import { FC, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import PushNotifications from "../PushNotifications";
import {
  CheckErrorContent,
  ErrorConstant,
  HttpStatusCodes,
  NumericConstants,
  ToastStatus,
} from "../../constants/CommonConstants";
import ToastComponent from "../ToastComponents";
import { getErrorAction } from "../../stateManagement/actions/securityActions";
import { RootState } from "../../stateManagement/services/rootDispatcher";

const MainErrorNofifySection: FC = () => {
  const [isNofityShow, errorData]: any = useSelector((state: RootState) => {
    return [state?.errorReducer?.isNofityShow, state?.errorReducer?.errorData];
  }, shallowEqual);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    let timer1: NodeJS.Timeout;
    if (isNofityShow) {
      setVisible(true);
      timer1 = setTimeout(() => {
        getErrorAction({ isNofityShow: false });
      }, NumericConstants.TOAST_AUTO_HIDE_MID_INTERVAL);
    }
    return () => {
      clearTimeout(timer1);
      setVisible(false);
    };
  }, [isNofityShow]);

  const getMessage = () => {
    switch (Number(errorData?.StatusCode)) {
      case 401:
        if (errorData?.StatusMessage === CheckErrorContent.TOKEN_EXPIREED) {
          return ErrorConstant.UNAUTHORIZED_USER;
        }
        return (
          errorData?.StatusMessage ||
          errorData?.statusText ||
          ErrorConstant.DEFAULT_ERROR_MESSAGE
        );
      default:
        return (
          errorData?.StatusMessage ||
          errorData?.message ||
          errorData?.statusText ||
          ErrorConstant.DEFAULT_ERROR_MESSAGE
        );
    }
  };
  useEffect(() => {
    if (isNofityShow) return () => {};
  }, [isNofityShow]);

  const handleToastClose = () => {
    setVisible(false);
  };

  if (isVisible) {
    if (
      Number(errorData?.StatusCode) ===
        HttpStatusCodes.CLIENT_ERROR_UNAUTHORIZED &&
      errorData?.StatusMessage === CheckErrorContent.TOKEN_EXPIREED
    ) {
      return (
        <ToastComponent
          isVisible={isVisible}
          showToastFor={ToastStatus?.FAIL}
          showToastContent={getMessage()}
          onClose={handleToastClose}
        />
      );
    } else {
      return (
        <PushNotifications
          message={getMessage()}
          severity={"error"}
          autoHide={NumericConstants.TOAST_AUTO_HIDE_MID_INTERVAL}
        />
      );
    }
  } else {
    return null;
  }
};

export default MainErrorNofifySection;
