import {
  FC,
  // useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styles, tableCellStyles, tableHeaderStyles } from "./styles";
import { formatTime } from "../../utils/CommonUtils";
import {
  Box,
  IconButton,
  Paper,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import { shallowEqual, useDispatch } from "react-redux";
import {
  fetchProductEvaluationDetailAction,
  fetchProductEvaluationList,
  fetchProductEvaluationResultAction,
} from "../../stateManagement/actions/dashboardActions";
import { useAppSelector } from "../../hooks/hooks";
import { tableHeaderFields } from "../../constants/TableColumnsDataConstants";
import { RemoveRedEye, Edit } from "@mui/icons-material";
import {
  NumericConstants,
  TitleConstants,
} from "../../constants/CommonConstants";
import {
  AppDispatch,
  RootState,
} from "../../stateManagement/services/rootDispatcher";

interface Propes {}
const ProductTable: FC<Propes> = (props: any) => {
  const navigateToRoute = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [productevaluationlist, isUserSelectionSuccess] = useAppSelector(
    (state: RootState) => {
      return [
        state.dashboardReducer?.productevaluationlist,
        state.securityReducer?.isUserSelectionSuccess,
      ];
    },
    shallowEqual
  );
  const tableRef = useRef<HTMLTableElement>(null); // Explicitly type the ref
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const fetchData = useCallback(() => {
  //   const getData = () => {
  //     dispatch(
  //       fetchProductEvaluationList({
  //         pageNumber: page + 1,
  //         pageSize: rowsPerPage,
  //       })
  //     );
  //   };
  //   if (isUserSelectionSuccess) {
  //     getData();
  //   }
  //   const intervalId = setInterval(getData, NumericConstants.INTERVAL_FIVE_MIN); // 5 minutes in milliseconds
  //   return () => clearInterval(intervalId);
  // }, [
  //   isUserSelectionSuccess,
  //   // dispatch,
  //   page,
  //   rowsPerPage,
  // ]);

  // useEffect(() => {
  //   const handlePageLoad = () => {
  //     dispatch(
  //       fetchProductEvaluationList({
  //         pageNumber: page + 1,
  //         pageSize: rowsPerPage,
  //       })
  //     );
  //   };
  //   if (document.readyState === "complete") {
  //     // If the page is already loaded, call the handler immediately
  //     handlePageLoad();
  //   } else {
  //     // Otherwise, wait for the window load event
  //     window.addEventListener("load", handlePageLoad);
  //     // Cleanup the event listener on component unmount
  //     return () => {
  //       window.removeEventListener("load", handlePageLoad);
  //     };
  //   }
  //   // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    // call it when get user details successful
    const getData = () => {
      dispatch(
        fetchProductEvaluationList({
          pageNumber: page + 1,
          pageSize: rowsPerPage,
        })
      );
    };
    if (isUserSelectionSuccess) {
      getData();
    }
    const intervalId = setInterval(getData, NumericConstants.INTERVAL_FIVE_MIN); // 5 minutes in milliseconds
    return () => {
      clearInterval(intervalId);
    };
  }, [isUserSelectionSuccess, dispatch, page, rowsPerPage]);

  useEffect(() => {
    // Effect to scroll to the top of the table when the data changes
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [productevaluationlist]);

  const navigateToOutputPage = (item: any) => {
    fetchProductEvaluationResultAction(item?.ExecutionRunId)
      .then(() => {
        navigateToRoute(RouteConstants.URL_PRODUCT_EVALUATION_OUTPUT, {
          state: { ...item },
        });
      })
      .catch((error) => {
        // Handle the error appropriately here
      });
  };

  const navigateToCreateRun = (item: any) => {
    fetchProductEvaluationDetailAction(item?.ExecutionDefinitionId)
      .then(() => {
        navigateToRoute(RouteConstants.URL_CREATE_NEW_RUN, {
          state: { ...item },
        });
      })
      .catch((error) => {
        // Handle the error appropriately here
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper>
      <TableContainer sx={styles.tableContainer}>
        <Table
          ref={tableRef}
          sx={styles.tableStyle}
          aria-label="simple table"
          stickyHeader
        >
          <TableHead sx={[styles.tableHeadStyle]}>
            <TableRow>
              {tableHeaderFields.PRODUCTEVALUATIONLIST.map(
                (item: any, index: number) => {
                  return (
                    <TableCell
                      key={`${item?.id}_${index}`}
                      sx={tableHeaderStyles(item?.id)}
                    >
                      {item?.label}
                    </TableCell>
                  );
                }
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {productevaluationlist?.List?.length > 0 ? (
              productevaluationlist?.List.map((row: any, index: any) => {
                return (
                  <TableRow key={index} sx={styles.tableContentRow}>
                    {tableHeaderFields.PRODUCTEVALUATIONLIST.map(
                      (column: any, index: any) => {
                        return (
                          <TableCell
                            key={`${column?.id}_${index}`}
                            sx={[styles.tableCell, tableCellStyles(column?.id)]}
                          >
                            {column?.id === "Action" ? (
                              <Box sx={[styles.iconContainerStyle]}>
                                <IconButton
                                  onClick={() => navigateToOutputPage(row)}
                                  sx={styles.viewIconButton}
                                >
                                  <Tooltip
                                    id={"button-view_output"}
                                    title={"View"}
                                  >
                                    <RemoveRedEye sx={styles.iconStyle} />
                                  </Tooltip>
                                </IconButton>
                                <IconButton
                                  onClick={() => navigateToCreateRun(row)}
                                  sx={styles.editIconButton}
                                >
                                  <Tooltip
                                    id={"button-create_new_run"}
                                    title={"Edit"}
                                  >
                                    <Edit
                                      sx={[
                                        styles.iconStyle,
                                        styles.pencilIconStyle,
                                      ]}
                                    />
                                  </Tooltip>
                                </IconButton>
                              </Box>
                            ) : column?.id === "StartTime" ? (
                              formatTime(row?.StartTime)
                            ) : column?.id === "EndTime" ? (
                              formatTime(row?.EndTime)
                            ) : (
                              row[column?.id] || ""
                            )}
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })
            ) : (
              <>
                <TableRow key={0}>
                  <TableCell
                    colSpan={tableHeaderFields.PRODUCTEVALUATIONLIST?.length}
                    align={"center"}
                    sx={styles.emptyDataCell}
                  >
                    {TitleConstants.NO_RECORDS}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        disabled={productevaluationlist?.TotalCount > 0 ? false : true}
        rowsPerPageOptions={
          productevaluationlist?.TotalCount ? [10, 30, 50] : []
        }
        component="div"
        count={productevaluationlist?.TotalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ProductTable;
