import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { colors } from "../../config/theme";

interface LoadingOverlayProps {
  isVisible: boolean;
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  isVisible,
}) => {
  return (
    <Backdrop
      sx={{
        color: colors?.fullWhite,
        zIndex: (theme: any) => theme.zIndex.drawer + 1,
      }}
      open={isVisible}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default LoadingOverlay;
