import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import {
  AppBar,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import useLogout from "../../hooks/useLogout";
import "./styles.css";
import { colors } from "../../config/theme";
import { ImagesAndLogos } from "../../constants/CommonConstants";
import store from "../../stateManagement/store";
import { resetRouteData } from "../../stateManagement/actions/routeActions";

const CommonToolbar = () => {
  const location = useLocation();
  const { logout } = useLogout();
  const hideToolbarPaths: string[] = [
    RouteConstants.URL_LOGIN,
    RouteConstants.URL_FORGOT_PASSWORD,
    "/",
    // Add other paths where you want the toolbar to be visible
  ];

  const handleLogout = () => {
    logout();
    setTimeout(() => {
      store.dispatch(resetRouteData());
    }, 2000);
  };

  const shouldHideToolbarRightIcon = hideToolbarPaths.includes(
    location.pathname as RouteConstants
  );
  return (
    <div>
      <AppBar elevation={0} position="fixed">
        <Toolbar className="toolbar">
          <img
            src={ImagesAndLogos?.LOGO}
            className="logoLeft lazyloaded"
            data-ll-status="loaded"
            alt="logo"
          />
          {shouldHideToolbarRightIcon ? null : (
            <IconButton color="inherit" title="Logout" onClick={handleLogout}>
              <Typography
                sx={{
                  marginRight: 1,
                  fontWeight: "500",
                  color: colors?.themeBlue,
                }}
              >
                Logout
              </Typography>
              <Tooltip id="button-create_new_run" title="Logout">
                <LogoutIcon sx={{ color: colors?.themeBlue }} />
              </Tooltip>
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default CommonToolbar;
