import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import RouteConstants from "../constants/RouteConstants";
import ResetValidator from "../components/Login/resetValidator";
import TenantSelection from "../components/Login/TenantSelection/TenantSelection";
import CommonToolbar from "../components/CommonToolbar";
import {
  CreateNewRun,
  ForgotPassword,
  Home,
  Login,
  Settings,
  ProductEvaluationOutput,
} from "../pages";
import NavigationDrawer from "../components/NavigationDrawer";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import PrivateRoutes from "../utils/PrivateRoutes";
import { saveRouteDataAction } from "../stateManagement/actions/routeActions";
import MainErrorNofifySection from "../components/Notification/MainErrorNofifySection";

const theme = createTheme();

const AppWrapper: React.FC = () => {
  const location = useLocation();
  const [currentPage, setcurrentPage] = useState(location?.pathname);
  useEffect(() => {
    if (location?.pathname) {
      const dataToSave = { prevousPageName: currentPage };
      saveRouteDataAction(dataToSave);
    }
    setcurrentPage(location.pathname);
    // eslint-disable-next-line
  }, [location?.pathname]);

  const showToolbarPaths: string[] = [
    RouteConstants.URL_LOGIN,
    RouteConstants.URL_FORGOT_PASSWORD,
    RouteConstants.URL_CREATE_NEW_RUN,
    RouteConstants.URL_HOME_PAGE,
    RouteConstants.URL_SETTINGS,
    RouteConstants.URL_PRODUCT_EVALUATION_OUTPUT,
    "/",
    // Add other paths where you want the toolbar to be visible
  ];

  const shouldShowToolbar = showToolbarPaths.includes(
    location.pathname as RouteConstants
  );

  const showNavigationDrawer: string[] = [
    RouteConstants.URL_HOME_PAGE,
    RouteConstants.URL_SETTINGS,
    RouteConstants.URL_CREATE_NEW_RUN,
    RouteConstants.URL_PRODUCT_EVALUATION_OUTPUT,
    // Add other paths where you want the toolbar to be visible
  ];

  const shouldShowNavigationDrawer = showNavigationDrawer.includes(
    location.pathname as RouteConstants
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box display="flex" height="100vh">
        {shouldShowNavigationDrawer && <NavigationDrawer />}
        <Box flexGrow={1} display="flex" flexDirection="column">
          <MainErrorNofifySection />
          {shouldShowToolbar && <CommonToolbar />}
          <Box flexGrow={1}>
            <Routes>
              <Route element={<PrivateRoutes />}>
                <Route path={RouteConstants.URL_HOME_PAGE} element={<Home />} />
                <Route
                  path={RouteConstants.URL_CREATE_NEW_RUN}
                  element={<CreateNewRun />}
                />
                <Route
                  path={RouteConstants.URL_PRODUCT_EVALUATION_OUTPUT}
                  element={<ProductEvaluationOutput />}
                />
                <Route
                  path={RouteConstants.URL_SETTINGS}
                  element={<Settings />}
                />
              </Route>
              <Route path="/" element={<Login />} />
              <Route path={RouteConstants.URL_LOGIN} element={<Login />} />
              <Route
                path={RouteConstants.URL_FORGOT_PASSWORD}
                element={<ForgotPassword />}
              />
              <Route
                path={RouteConstants.URL_RESET_PASSWORD}
                element={<ResetValidator />}
              />
              <Route
                path={RouteConstants.URL_TENANT_SELECTION}
                element={<TenantSelection />}
              />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AppWrapper;
