import AddCircle from "@mui/icons-material/AddCircle";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { shallowEqual } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TitleConstants } from "../../../constants/CommonConstants";
import RouteConstants from "../../../constants/RouteConstants";
import LoadingOverlay from "../../../components/Loader/LoadingOverlay";
import { styles } from "./styles";
import ProductTable from "../../../components/HomeComponent/ProductTable";
import { useAppSelector } from "../../../hooks/hooks";
import { resetProductEvaluationRequest } from "../../../stateManagement/actions/createNewRunActions";
import { RootState } from "../../../stateManagement/services/rootDispatcher";

const Home = () => {
  const navigateToRoute = useNavigate();
  const [listLoading, loginLoader] = useAppSelector((state: RootState) => {
    return [
      state.dashboardReducer?.listLoading,
      state.securityReducer?.loginLoader,
    ];
  }, shallowEqual);

  const navigateToCreateRun = () => {
    resetProductEvaluationRequest();
    navigateToRoute(RouteConstants.URL_CREATE_NEW_RUN);
  };

  return (
    <div style={styles?.mainContain}>
      <LoadingOverlay isVisible={loginLoader || listLoading} />
      <Box sx={styles?.mainTableContainer}>
        <Box sx={styles?.labelButtonContainer}>
          <IconButton
            color="inherit"
            title={TitleConstants.CREATE_A_NEW_RUN}
            onClick={navigateToCreateRun}
          >
            <Typography sx={styles?.labelText}>
              {TitleConstants.CREATE_A_NEW_RUN}
            </Typography>
            <Tooltip
              id="button-create_new_run"
              title={TitleConstants.CREATE_A_NEW_RUN}
            >
              <AddCircle />
            </Tooltip>
          </IconButton>
        </Box>
        <ProductTable />
      </Box>
    </div>
  );
};

export default Home;
