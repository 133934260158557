import { NumericConstants } from "../../constants/CommonConstants";

import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  INCREMENT_LOGIN_ATTEMPTS,
  RESET_LOGIN_ATTEMPTS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_VALIDATOR_SUCCESS,
  RESET_VALIDATOR_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  GET_TENANT_DETAILS_SUCCESS,
  GET_TENANT_DETAILS_FAILURE,
  LOGOUT,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_REQUEST,
  RESET_AUTH_TOKEN,
  LOGIN_LOADER,
  FORGOT_PASSWORD_REQUEST,
  TENANT_DETAILS_REQUEST,
  RESET_SECURITY_REQUEST,
  RESET_PASSWORD_REQUEST,
} from "../types";

export const securityInitialState = {
  authToken: null,
  user: null,
  isLoggedIn: false,
  loginAttempts: 0,
  isLoginDisabled: false,
  errorMessage: null,
  success: false,
  successMessage: null,
  validationSuccess: false,
  validationData: null,
  isResetPasswordSuccess: false,
  isResetPasswordFail: false,
  resetMessage: null,
  isTenantSelectionSuccess: false,
  tenantDetailSuccessMsg: null,
  tenantDetailFailMsg: null,
  isUserSelectionSuccess: false,
  userDetailsSuccessMsg: null,
  userDetailsDetailFailMsg: null,
  loginLoader: false,
  loginError: null,
};

const reducer = (state = securityInitialState, action: any) => {
  switch (action.type) {
    case LOGOUT: // Handle logout action
      return {
        ...securityInitialState, // Reset state to initial state when logging out
      };
    case RESET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.payload,
      };
    case INCREMENT_LOGIN_ATTEMPTS:
      return {
        ...state,
        loginAttempts: state.loginAttempts + 1,
        isLoginDisabled:
          state.loginAttempts + 1 >= NumericConstants.MAX_LOGIN_ATTEMPTS,
      };
    case RESET_LOGIN_ATTEMPTS:
      return {
        ...state,
        loginAttempts: 0,
        isLoginDisabled: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        loginLoader: true,
        loginError: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        loginLoader: false,
        loginError: action.payload,
      };
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        success: false,
        successMessage: null,
        loginLoader: true,
      };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        success: true,
        successMessage: action.payload,
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        success: false,
        errorMessage: action.payload?.StatusMessage,
        loginLoader: false,
      };
    case RESET_VALIDATOR_SUCCESS:
      return {
        ...state,
        validationSuccess: true,
        validationData: action.payload,
      };
    case RESET_VALIDATOR_FAILURE:
      return {
        ...state,
        validationSuccess: false,
        validationData: action.payload,
      };
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetMessage: null,
        isResetPasswordSuccess: false,
        isResetPasswordFail: false,
        loginLoader: true,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetMessage: action.payload,
        isResetPasswordSuccess: true,
        isResetPasswordFail: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetMessage: action.payload,
        isResetPasswordSuccess: false,
        isResetPasswordFail: true,
      };
    case TENANT_DETAILS_REQUEST:
      return {
        ...state,
        isTenantSelectionSuccess: false,
        tenantDetailSuccessMsg: null,
        tenantDetailFailMsg: null,
        loginLoader: true,
      };
    case GET_TENANT_DETAILS_SUCCESS:
      return {
        ...state,
        isTenantSelectionSuccess: true,
        tenantDetailSuccessMsg: action.payload,
      };
    case GET_TENANT_DETAILS_FAILURE:
      return {
        ...state,
        isTenantSelectionSuccess: false,
        tenantDetailFailMsg: action.payload,
        loginLoader: false,
        isLoggedIn: false,
      };
    case GET_USER_DETAILS_REQUEST:
      return {
        ...state,
        isUserSelectionSuccess: false,
        userDetailsSuccessMsg: null,
        userDetailsDetailFailMsg: null,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        isUserSelectionSuccess: true,
        userDetailsSuccessMsg: action.payload,
      };
    case GET_USER_DETAILS_FAILURE:
      return {
        ...state,
        isUserSelectionSuccess: false,
        userDetailsDetailFailMsg: action.payload?.StatusMessage,
      };
    case LOGIN_LOADER:
      return {
        ...state,
        loginLoader: action.payload,
      };
    case RESET_SECURITY_REQUEST:
      return {
        ...state,
        loginLoader: false,
        isTenantSelectionSuccess: false,
        tenantDetailFailMsg: null,
        tenantDetailSuccessMsg: null,
        isUserSelectionSuccess: false,
        userDetailsSuccessMsg: null,
        userDetailsDetailFailMsg: null,
        resetMessage: null,
        isResetPasswordSuccess: false,
        validationSuccess: false,
        validationData: null,
        success: false,
        errorMessage: null,
        loginError: null,
        loginAttempts: 0,
        isLoginDisabled: false,
      };

    default:
      return state;
  }
};

export default reducer;
