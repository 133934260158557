import { FC, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";

interface SnackbarProps {
  message: string;
  autoHide: number;
  severity: AlertColor | undefined;
  onClose?: () => void;
}

const PushNotifications: FC<SnackbarProps> = ({
  message,
  severity = "error",
  autoHide = 10000,
  onClose,
}): JSX.Element => {
  const [openPushNotifications, setOpenPushNotifications] = useState(true);

  const handleClosePushNotifications = () => {
    setOpenPushNotifications(false);
  };

  return (
    <Snackbar
      open={openPushNotifications}
      autoHideDuration={autoHide}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      onClose={onClose || handleClosePushNotifications}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
};

export default PushNotifications;
