export enum TokenConstants {
  TOKEN_AUTH_HEADER = "Authorization",
  TOKEN_NAME = "JWTToken",
  TENANT_ID_KEY = "TenantId",
  COMPANY_ID_KEY = "CompanyId",
}

export enum StorageKeys {
  FORGET_TO_LOGIN = "forgotToLogin",
  RESET_TO_LOGIN = "resetToLogin",
  RESET_VALIDATOR_TO_LOGIN = "resetValidatorFails",
  RESET_PASSWORD_TOKEN = "ResetPasswordToken",
}

export enum TitleConstants {
  LOGIN = "Login",
  EMAIL = "Email",
  PASSWORD = "Password",
  FORGOT_PASSWORD = "Forgot Password?",
  REGISTER = "Register",
  NEED_HELP = "Need Help?",
  INVALID_USERNAME_PASSWORD = "Username and Password is invalid",
  ACCOUNT_LOCKED = "Your account is locked. Please try after some time or reset your password using forgot password",
  FORGOT_YOUR_PASSWORD = "Forgot your password",
  CONTINUE = "Continue",
  CANCEL = "Cancel",
  EDIT = "Edit",
  CLOSE = "Close",
  SUBMIT = "Submit",
  NAME_OF_THE_PRODUCT_EVALUATION_RUN = "Name of this Product Evaluation Run",
  CREATE_A_NEW_RUN = "Create a New Run",
  MAXIMUM_RX_COUNT_PREFERENCE_IN_ORANGE_BOOK = "Orange Book - Max Number of Actives",
  MAXIMUM_COUNT_PREFERENCE_FROM_ANALYSOURCE = "FDB - Max Number of Actives",
  ONCE_SUBMIT_EMAIL_ADDRESS = "Once you submit the email address, we will send you an email containing a personalized link with instruction to reset your password.",
  SOMETHING_WENT_WRONG = "Something went wrong. Please contact your administrator or retry again.",
  LOADING_INDICATOR = "...Loading",
  CREATE_NEW_PASSWORD = "Create new password",
  PLEASE_GENERATE_ANOTHER_RESET_PASSWORD_REQUEST = "Please generate another reset password request as the provided link has expired",
  RESET_PASSWORD_NOTIFICATION_HAS_BEEN_SENT = "A Reset Password Notification has been sent.Please follow the instructions to reset your password!",
  THE_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY = "The password has been reset successfully",
  USER_HAS_NO_TENANT_ASSIGNED_SO_THE_USER_IS_SUSPENDED = "User has no tenant assigned so the user is suspended",
  FETCH_NEW_DATASET_FROM_FDA = "Fetch New Dataset from FDA Off-Patent Drugs List? (If not, most recent pull will be used)",
  PREFERRED_DOSAGE_FORM = "Preferred Dosage Form(s)",
  NOT_SUPPORTED_PRODUCT_OWNERS_FOR_TRADEMARK = "Any Companies to Exclude from Trademark Search? ",
  FETCH_NEW_ORANGE_BOOK = "Fetch new Orange Book (If not, most recent pull will be used)",
  PRODUCT_EVALUATION_RUN_SUBMIITED_SUCCESSFULLY = "Product Evaluation Run submiited successfully",
  PRODUCT_EVALUATION_RUN_INPUT_PROVIDED = "Product Evaluation Run Input Provided",
  FDA_OFF_PATENT_DRUGS_LIST_RESULTS = "FDA Off-Patent Drugs List Results",
  ORANGE_BOOK_RESULTS = "Orange Book Results",
  FDB_RESULTS = "Analysource Results",
  TRADEMARK_RESULTS = "Trademark Results",
  EXPORT = "EXPORT",
  EXPORT_TO_CSV = "Export to CSV",
  EXPORT_TO_EXCEL = "Export to Excel",
  PRODUCT_EVALUATION_RUN_SUBMIITED_FAILED = "Product Evaluation Run submiition Failed",
  NO_RECORDS = "No record found",
  FINAL_RESULT = "Final Results",
}

export enum NumericConstants {
  MAX_LOGIN_ATTEMPTS = 5,
  TOAST_AUTO_HIDE_INTERVAL = 10000,
  PASSWORD_MIN_LENGTH = 8,
  PASSWORD_MAX_LENGTH = 16,
  TOAST_AUTO_HIDE_SMALL_INTERVAL = 3000,
  TOAST_AUTO_HIDE_MID_INTERVAL = 5000,
  INTERVAL_FIVE_MIN = 300000,
}

export enum ValidationConstants {
  EMAIL_VALIDATION = "Invalid Email ID",
  TEXT_FIELD_EMPTY = "Please enter value",
  PLEASE_ENTER_NAME_OF_THE_PRODUCT_EVALUATION_RUN = "Please enter name of the Product Evaluation Run",
  PLEASE_ENTER_MAXIMUM_RX_COUNT_PREFERENCE_IN_ORANGE_BOOK = "Please enter Orange Book - Max Number of Actives",
  PLEASE_ENTER_MAXIMUM_COUNT_PREFERENCE_FROM_ANALY_SOURCE = "Please enter FDB - Max Number of Actives",
  PLEASE_SELECT_PREFERRED_DOSAGE_FORM = "Please select Preferred Dosage Form",
  PLEASE_ENTER_NOT_SUPPORTED_PRODUCT_OWNERS = "Please enter not Supported Product Owners",
  ENTER_PASSWORD = "Please enter password",
  PASSWORD_MAX_LENGTH = "Max Length = 16 characters",
  PASSWORD_MIN_LENGTH = "Min Length = 8 characters",
  PASSWORD_INPUTS = "Inputs:",
  PASSWORD_INPUT_CASE_RULE = "At least one of each alphabets[lower case, upper case both],",
  PASSWORD_INPUT_NUMERAL_RULE = "At least one numeral[0-9],",
  PASSWORD_INPUT_SPECIAL_CHARACTER_RULE = "At least one special characters",
  AT_LEAST_ONE_TAG_IS_REQUIRED = "At least one tag is required",
}

export enum HttpStatusCodes {
  SUCCESS_OK = 200,
  SUCCESS_CREATED = 201,
  SUCCESS_ACCEPTED = 202,

  CLIENT_ERROR_BAD_REQUEST = 400,
  CLIENT_ERROR_UNAUTHORIZED = 401,
  CLIENT_ERROR_FORBIDDEN = 403,
  CLIENT_ERROR_NOT_FOUND = 404,

  SERVER_ERROR_INTERNAL_ERROR = 500,
  SERVER_ERROR_NOT_IMPLEMENTED = 501,
  SERVER_ERROR_SERVICE_UNAVAILABLE = 502,
}

export enum SettingsConstants {
  ORGANIZATION_NAME = "Organization Name",
  FIRST_NAME = "First Name",
  MIDDLE_NAME = "Middle Name",
  LAST_NAME = "Last Name",
  EMAIL_ADDRESS = "Email Address",
  ALTERNATIVE_EMAIL_ADDRESS = "Alternative Email Address",
  PHONE_NUMBER = "Phone Number",
  USER_IMAGE = "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_1280.png",
}

export enum ProductEvaluationStatus {
  NotStarted = 1,
  InProgress = 2,
  Error = 3,
  COMPLETED = 4,
}

export enum CheckErrorContent {
  TOKEN_EXPIREED = "Jwt Token is invalid",
}

export enum ErrorConstant {
  INVALID_USERNAME_PASSWORD = "Username and Password is invalid",
  UNAUTHORIZED_USER = "Unsuthorized access!",
  DEFAULT_ERROR_MESSAGE = "Something went wrong",
  RESET_PAWWORD_FAILED = "An error has occured. Please attempt forgot password again and reset your password or contact the administrator to review the problem",
}
export enum AxiosErrorConstant {
  NETWORK_ERROR = "ERR_NETWORK",
}

export enum ImagesAndLogos {
  LOGO = "https://s3.amazonaws.com/dagny.public/dagny-logo-2.png",
  PORDUCT_LOGO = "https://galtrx.com/wp-content/uploads/2021/12/Galt-Logo-Blue-Transparent.png",
  StockImage = "https://s3.amazonaws.com/dagny.public/blueorangelights.jpg",
  // StockImage = "https://generation-sessions.s3.amazonaws.com/19e09073baac437410bc14dacac198f3/img/colleagues-discussing-business-strategies-together-1.png", //previous used
}

export enum ToastStatus {
  SUCCESS = "SUCCESS",
  FAIL = "FAIL",
}

export enum ControllerNames {
  EXECUTION_DEFINATION_NAME = "ExecutionDefinitionName",
  FDA_OFF_PARENT_FRESS_DATA = "FDAOffPatentFreshDataDownloadBl",
  ORANGE_BOOK_MAX_COUNT = "OrangeBookMaxRXCount",
  ANALYSOURCE_MAX_ACTIVE_COUNT = "AnalysourceMaxActiveCount",
  TAGS = "TrademarkIgnoreBrandList",
  PREFERRED_DOSAGE_FORM = "SelectedDosageForms",
  ORABGE_BOOK_FRESH_DATA = "OrangeBookFreshDataDownloadBl",
}

export enum Keys {
  ENTER = "Enter",
}

export enum OutputResultKeys {
  USER_INPUTS_PROVIDER = "Input",
  TRADEMARK = "ResultStep4",
  FDB_RESULTS = "ResultStep3",
  ORANGE_BOOK = "ResultStep2",
  FDA_OFF_PATENT = "ResultStep1",
}
