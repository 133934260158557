export const dataResolve = (dataList: any[], compareList: any[]) => {
  if (dataList && compareList) {
    return compareList.reduce(
      (acc, obj1) => {
        const found = acc.find((obj2: any) => obj2.Id === obj1.Id);
        if (!found && obj1.Id) {
          acc.push(obj1);
          // if (obj1?.ActiveBl) {
          //   acc.push(obj1);
          // } else {
          //   acc.push({ ...obj1, ActiveBl: true });
          // }
        }
        return acc;
      },
      [...dataList]
    );
  }
};

export const rearrangeData = (data: any) => {
  if (data.SelectedDosageForms) {
    const newList: any[] = data.SelectedDosageForms.filter(
      (item: any) => item?.Id
    ).map((element: any) => {
      return { ...element };
      // if (element?.ActiveBl === undefined) {
      //   return { ...element, ActiveBl: true };
      // } else {
      // return { ...element };
      // }
    });
    data.SelectedDosageForms = [...newList];
  }
  return { ...data };
};
