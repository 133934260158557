import {
  AxiosErrorConstant,
  HttpStatusCodes,
} from "../../../constants/CommonConstants";
import { isTokenSameAsPervious, setAuthToken } from "../../../utils/authToken";
import { resetAll } from "../../actions/resetActions";
import { getAuthAction, getErrorAction } from "../../actions/securityActions";
import instance from "./baseServices";

interface apiObject {
  url: string;
  method: string;
  headers?: any;
  data?: any;
  maxBodyLength?: number;
}

export const ApiClient = (obj: apiObject) => {
  getErrorAction({ isNofityShow: false });
  return new Promise<any>((resolve, reject) => {
    instance(obj)
      .then((response) => {
        if (isTokenSameAsPervious(response?.data?.PayLoad?.JWTToken)) {
          setAuthToken(response?.data?.PayLoad?.JWTToken);
          getAuthAction(response?.data?.PayLoad?.JWTToken);
        }
        resolve(response);
      })
      .catch((error) => {
        errorManage(error);
        if (error?.code === AxiosErrorConstant?.NETWORK_ERROR) {
          reject(error?.response || error);
        } else {
          reject(error?.response);
        }
      });
  });
};

export const errorManage = async (error: any, navigate?: any) => {
  const [errorData, errorStatus]: any = handleErrorData(error);

  switch (errorStatus) {
    case HttpStatusCodes.CLIENT_ERROR_BAD_REQUEST:
      console.error("Bad request:", errorData);
      getErrorAction({ ...errorData, isNofityShow: true });
      break;
    case HttpStatusCodes.CLIENT_ERROR_UNAUTHORIZED:
      // for reset and auto logout when 401 occures
      console.error("Unauthorized:", errorData);
      await resetAll().then(async () => {
        getErrorAction({ ...errorData, isNofityShow: true });
      });
      break;
    case HttpStatusCodes.CLIENT_ERROR_FORBIDDEN:
      console.error(":", errorData);
      getErrorAction({ ...errorData, isNofityShow: true });
      break;
    case HttpStatusCodes.CLIENT_ERROR_NOT_FOUND:
      console.error("Page not found:", errorData);
      getErrorAction({ ...errorData, isNofityShow: true });
      break;
    case HttpStatusCodes.SERVER_ERROR_INTERNAL_ERROR:
      console.error("Internal Server Error:", errorData);
      getErrorAction({ ...errorData, isNofityShow: true });
      break;
    default:
      console.error("Error[]:", errorData || error);
      if (error?.code === "ERR_NETWORK") {
        getErrorAction({ ...errorData, isNofityShow: true });
      }
      break;
  }
  if (error.request) {
    // The request was made but no response was received
    // console.error("No response received:", error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    // console.error("Triggered an Error[]:", error.message);
  }
};

export const handleErrorData = (error: any) => {
  const dbResponseStatus = Number(error?.response?.data?.StatusCode);
  const responseStstus = error?.response?.status || error?.status;
  const errorStatus = !isNaN(dbResponseStatus)
    ? dbResponseStatus
    : responseStstus;
  let errorData = error?.response?.data || error?.response || error;
  // Check if errorData contains HTML tags
  const isHtml = typeof errorData === "string" && errorData.includes("<html");
  // If errorData is HTML, use error?.response, otherwise use errorData
  if (isHtml) {
    errorData = error?.response || error;
  }
  return [errorData, errorStatus];
};
