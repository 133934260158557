import { combineReducers } from "redux";
import securityReducer, { securityInitialState } from "./securityReducers";
import dashboardReducer, { dashboardInitialState } from "./dashboardReducers";
import createNewRunReducer, { createNewRunInitialState } from "./createNewRunReducers";
import historyReducer from "./historyReducer";
import errorReducer from "./errorReducer";
import { LOGOUT } from "../types";

const reducers = combineReducers({
  securityReducer,
  dashboardReducer,
  createNewRunReducer,
  historyReducer,
  errorReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    state = {
      securityReducer: securityInitialState,
      dashboardReducer: dashboardInitialState,
      createNewRunReducer: createNewRunInitialState,
      historyReducer: state.historyReducer, // Preserve historyReducer
      errorReducer: state.errorReducer, // Preserve errorReducer
    };
  }
  return reducers(state, action);
};

export default rootReducer;
