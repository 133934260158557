import React, { FC, useCallback, useEffect, useState } from "react";
import ToastComponent from "../ToastComponents";
import { shallowEqual, useSelector } from "react-redux";
import {
  NumericConstants,
  StorageKeys,
  TitleConstants,
  ToastStatus,
} from "../../constants/CommonConstants";
import {
  getErrorAction,
  resetRequest,
} from "../../stateManagement/actions/securityActions";
import { RootState } from "../../stateManagement/services/rootDispatcher";

const ToastSectionLogin: FC = () => {
  const [
    {
      tenantDetailFailMsg,
      success,
      isResetPasswordSuccess,
      isResetPasswordFail,
      resetMessage,
      validationSuccess,
      validationData,
    },
  ] = useSelector((state: RootState) => {
    return [state.securityReducer];
  }, shallowEqual);
  // const forgotToLogin: any = localStorage.getItem(StorageKeys.FORGET_TO_LOGIN);
  // const forgotToLoginParse = JSON.parse(forgotToLogin);
  const resetValidatorFails: any = localStorage.getItem(
    StorageKeys.RESET_VALIDATOR_TO_LOGIN
  );
  const resetValidatorFailsParse = JSON.parse(resetValidatorFails);
  const resetToLogin: any = localStorage.getItem(StorageKeys.RESET_TO_LOGIN);
  const resetToLoginParse = JSON.parse(resetToLogin);

  const [isVisible, setVisible] = useState(false);

  const reset = useCallback(() => {
    localStorage.removeItem(StorageKeys.RESET_VALIDATOR_TO_LOGIN);
    localStorage.removeItem(StorageKeys.RESET_TO_LOGIN);
    localStorage.removeItem(StorageKeys.FORGET_TO_LOGIN);
  }, []);

  useEffect(() => {
    let timer1: NodeJS.Timeout;
    if (
      !isVisible &&
      (success ||
        tenantDetailFailMsg ||
        resetValidatorFailsParse ||
        resetToLoginParse)
    ) {
      setVisible(true);
      timer1 = setTimeout(() => {
        resetRequest();
        reset();
      }, NumericConstants.TOAST_AUTO_HIDE_MID_INTERVAL);
    }
    return () => {
      setVisible(false);
      clearTimeout(timer1);
    };
    // eslint-disable-next-line
  }, [
    success,
    tenantDetailFailMsg,
    resetValidatorFailsParse,
    resetToLoginParse,
  ]);

  const handleToastClose = () => {
    setVisible(false);
    getErrorAction({ isNofityShow: false });
  };

  const toastType = () => {
    if (
      success ||
      resetToLoginParse ||
      isResetPasswordSuccess ||
      validationSuccess
    ) {
      return ToastStatus.SUCCESS;
    } else if (
      validationData?.PayLoad?.isTokenValid ||
      tenantDetailFailMsg ||
      resetValidatorFailsParse ||
      isResetPasswordFail
    ) {
      return ToastStatus.FAIL;
    }
  };

  const openToastFor = () => {
    if (tenantDetailFailMsg) {
      return TitleConstants.USER_HAS_NO_TENANT_ASSIGNED_SO_THE_USER_IS_SUSPENDED;
    } else if (resetValidatorFailsParse) {
      return TitleConstants.PLEASE_GENERATE_ANOTHER_RESET_PASSWORD_REQUEST;
    } else if (isResetPasswordFail) {
      return (
        resetMessage?.StatusMessage ||
        resetMessage ||
        TitleConstants.PLEASE_GENERATE_ANOTHER_RESET_PASSWORD_REQUEST
      );
    } else if (success) {
      return TitleConstants.RESET_PASSWORD_NOTIFICATION_HAS_BEEN_SENT;
    } else if (resetToLoginParse || isResetPasswordSuccess) {
      return (
        resetMessage?.StatusMessage ||
        resetMessage ||
        TitleConstants.THE_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY
      );
    } else {
      return "";
    }
  };

  return (
    <ToastComponent
      isVisible={isVisible}
      showToastFor={toastType()}
      showToastContent={openToastFor()}
      onClose={handleToastClose}
      customStyles={{
        color: "black",
        fontSize: "12px",
      }}
    />
  );
};

export default React.memo(ToastSectionLogin);
