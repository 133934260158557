// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 150px;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px; /* Optional, adjust the width as needed */
  margin: 0 auto; /* Optional, center the form on the page */
}

.form-controls {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  line-height: 24.2px;
  font-weight: 400;
}

label {
  margin-top: 25px;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/TenantSelection/styles/TenantSelection.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB,EAAE,yCAAyC;EAC3D,cAAc,EAAE,0CAA0C;AAC5D;;AAEA;EACE,gCAAgC;EAChC,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".form-select {\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 5px;\n  font-size: 16px;\n  width: 150px;\n}\n\n.form-container {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  max-width: 300px; /* Optional, adjust the width as needed */\n  margin: 0 auto; /* Optional, center the form on the page */\n}\n\n.form-controls {\n  font-family: 'Inter', sans-serif;\n  font-size: 20px;\n  line-height: 24.2px;\n  font-weight: 400;\n}\n\nlabel {\n  margin-top: 25px;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
