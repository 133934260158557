import store, { persistor } from "../store";
import { logout } from "./securityActions";
import RouteConstants from "../../constants/RouteConstants";
import { setAuthToken } from "../../utils/authToken";
import { saveRouteDataAction } from "./routeActions";

export const resetAll = (props?: any) => {
  const currentHistoryState = store.getState()?.historyReducer;
  // Save these details in sessionStorage
  return new Promise<void>((resolve) => {
    // Dispatch custom event
    persistor.pause();
    persistor
      .flush()
      .then(() => {
        return persistor.purge();
      })
      .then(async () => {
        setAuthToken(undefined);
        localStorage.clear();
        store.dispatch(logout());
        await saveRouteDataAction(currentHistoryState).then(() => {
          const event = new Event("tokenChanged");
          window.dispatchEvent(event);
          if (props?.navigate) {
            props?.navigate(RouteConstants.URL_LOGIN);
          }
          resolve();
        });
      })
      .catch((error: any) => {
        console.warn("errorInResetAll[]:", error);
      });
  });
};

export const handle401Error = (navigate: any) => {
  resetAll().then(() => {
    navigate(RouteConstants.URL_LOGIN);
  });
};
