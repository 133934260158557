import { ApiClient } from "./apiServices/apiClient";
import {
  LoginRoute,
  TenantDetailsRoute,
  UserDetailRoute,
  forgotPasswordRoute,
  resetPasswordRoute,
  resetPasswordValidatorRoute,
} from "./apiServices/routeObjects";

export const loginService = async (encryptedUserCredentials: any) => {
  try {
    let obj = {
      ...LoginRoute,
      data: { ...encryptedUserCredentials },
    };
    const response = await ApiClient(obj);
    return response!.data;
  } catch (error: any) {
    // const statusMessage = error?.data?.StatusMessage;
    throw error;
  }
};

export const forgotPasswordService = async (encryptedUserCredentials: any) => {
  try {
    let obj = {
      ...forgotPasswordRoute,
      data: encryptedUserCredentials,
    };

    const response = await ApiClient(obj);
    return response?.data;
  } catch (error: any) {
    throw error.data || error;
  }
};

export const resetPasswordService = async (body: any) => {
  try {
    let obj = {
      ...resetPasswordRoute,
      data: body,
    };
    const response = await ApiClient(obj);
    return response?.data;
  } catch (error: any) {
    throw error.data || error;
  }
};

export const resetPasswordValidatorService = async (body: any) => {
  try {
    let obj = {
      ...resetPasswordValidatorRoute,
      data: body,
    };
    const response = await ApiClient(obj);
    return response?.data;
  } catch (error: any) {
    throw error.data || error;
  }
};

export const getTenantDetailsService = async () => {
  try {
    let obj = {
      ...TenantDetailsRoute,
    };
    const response = await ApiClient(obj);
    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const getUserDetailService = async (data: any) => {
  try {
    const params = `?TenantId=${data?.UserTenantDetails[0]?.TenantId}&CompanyId=${data?.UserTenantDetails[0]?.CompanyId}`;
    const response = await ApiClient({
      ...UserDetailRoute(params),
    });
    return response!.data;
  } catch (error: any) {
    throw error?.data || error;
  }
};
