import {
  GET_DOSAGE_FORM_DATA_SUCCESS,
  GET_DOSAGE_FORM_DATA_FAILURE,
  SAVE_PRODUCT_EVALUATION_FAILURE,
  SAVE_PRODUCT_EVALUATION_SUCCESS,
  SAVE_PRODUCT_EVALUATION_REQUEST,
  GET_DOSAGE_FORM_DATA_REQUEST,
  CREATE_RUN_PAGE_LOADER,
  RESET_PRODUCT_EVALUATION_REQUEST,
} from "../types";

export const createNewRunInitialState = {
  isDosageFormSuccess: false,
  isDosageFormFail: false,
  dosageFormList: null,
  dosageFormFailMsg: null,
  isProductevaluationSuccess: false,
  isProductevaluationFail: false,
  productevaluationlist: null,
  productevaluationFailMsg: null,
  requestLoader: false,
  requestData: null,
  error: null,
};
const createNewRunReducer = (state = createNewRunInitialState, action: any) => {
  switch (action.type) {
    case GET_DOSAGE_FORM_DATA_SUCCESS:
      return {
        ...state,
        isDosageFormSuccess: true,
        dosageFormList: action.payload,
        dosageFormFailMsg: null,
        isDosageFormFail: false,
      };
    case GET_DOSAGE_FORM_DATA_FAILURE:
      return {
        ...state,
        isDosageFormSuccess: false,
        isDosageFormFail: true,
        dosageFormList: null,
        dosageFormFailMsg: action.payload,
      };
    case SAVE_PRODUCT_EVALUATION_SUCCESS:
      return {
        ...state,
        isProductevaluationSuccess: true,
        isProductevaluationFail: false,
        productevaluationlist: action.payload,
        productevaluationFailMsg: null,
        requestLoader: false,
      };
    case SAVE_PRODUCT_EVALUATION_FAILURE:
      return {
        ...state,
        isProductevaluationSuccess: false,
        isProductevaluationFail: true,
        productevaluationlist: null,
        productevaluationFailMsg: action.payload,
        requestLoader: false,
      };

    case GET_DOSAGE_FORM_DATA_REQUEST:
      return {
        ...state,
        isDosageFormSuccess: false,
        isDosageFormFail: false,
        dosageFormList: null,
        isProductevaluationSuccess: false,
        isProductevaluationFail: false,
      };
    case SAVE_PRODUCT_EVALUATION_REQUEST:
      return {
        ...state,
        isProductevaluationSuccess: false,
        isProductevaluationFail: false,
        productevaluationlist: null,
        productevaluationFailMsg: null,
        requestData: action.payload,
      };
    case CREATE_RUN_PAGE_LOADER:
      return {
        ...state,
        requestLoader: action.payload,
      };
    case RESET_PRODUCT_EVALUATION_REQUEST:
      return {
        ...state,
        requestData: createNewRunInitialState.requestData || null,
      };
    default:
      return state;
  }
};

export default createNewRunReducer;
