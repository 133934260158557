import React from "react";
import { Typography } from "@mui/material";
import "./settingsPage.css";
import { settingComponentStyles as styles } from "./styles";

interface InfoItemProps {
  label: string;
  value: string;
}

const InfoItem: React.FC<InfoItemProps> = ({ label, value }) => {
  return (
    <div className="info">
      <Typography className="label" sx={styles.labelStyle}>
        {label}
      </Typography>
      <Typography variant="subtitle1" className="value" sx={styles.valueStyles}>
        {value}
      </Typography>
    </div>
  );
};

export default InfoItem;
