enum RouteConstants {
  URL_ROOT = "/",
  URL_HOME_PAGE = "/home",
  URL_TENANT_SELECTION = "/tenantSelection",
  URL_LOGIN = "/login",
  URL_FORGOT_PASSWORD = "/forgotPassword",
  URL_RESET_PASSWORD = "/resetPassword",
  URL_REGISTER = "/register",
  URL_NEED_HELP = "/needHelp",
  URL_CREATE_NEW_RUN = "/createNewRun",
  URL_SETTINGS = "/settings",
  URL_PRODUCT_EVALUATION_OUTPUT = "/productEvaluationOutput",
}

export default RouteConstants;
