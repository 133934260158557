import React, { useCallback, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { tableHeaderFields } from "../../constants/TableColumnsDataConstants";
import { splitByIndex, splitLines } from "../TextLabelComponent/SplitingText";
import { formatValue } from "../../stateManagement/actions/dashboardActions";
import { keyValueTableStyles as styles } from "./styles";

interface KeyValueTableProps {
  data: any;
  additionalData?: any;
}

const KeyValueTable: React.FC<KeyValueTableProps> = ({
  data,
  additionalData,
}) => {
  const [inputFields, setInputfields] = useState<any>({});
  const [tableFields, setTableFields] = useState<any[]>([]);
  const updateData = useCallback(() => {
    if (data && Object.keys(data).includes("ExecutionDefinitionId")) {
      let newData = { ...data };
      delete newData?.ExecutionDefinitionId;
      newData.ExecutionRunId = additionalData?.ExecutionRunId;
      setInputfields(newData);
      setTableFields(tableHeaderFields.PRODUCT_EVALUATION_RUN_INPUT_PROVIDER);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    updateData();
    return () => {};
  }, [updateData]);

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table>
        <TableBody>
          {tableFields.map((key: any, index: number) => {
            return (
              <TableRow key={`${key?.key}_${index}`}>
                {index === 0 && key?.key === Object.keys(inputFields)[0] ? (
                  <>
                    <TableCell style={styles.fieldLabelStyle}>
                      <strong>{key?.value}</strong>
                    </TableCell>
                    <TableCell colSpan={3}>
                      {inputFields[key?.key] || ""}
                    </TableCell>
                  </>
                ) : index % 2 === 1 ? (
                  <>
                    <TableCell style={styles.fieldLabelStyle}>
                      <strong>{splitLines(key?.value || "")}</strong>
                    </TableCell>
                    <TableCell style={styles.fieldValueStyle}>
                      {splitByIndex(
                        formatValue(key?.key, inputFields[key?.key] || "")
                      )}
                    </TableCell>
                    {tableFields[index + 1] ? (
                      <>
                        <TableCell style={styles.fieldLabelStyle}>
                          {splitLines(tableFields[index + 1]?.value || "")}
                        </TableCell>
                        <TableCell style={styles.fieldValueStyle}>
                          {splitByIndex(
                            formatValue(
                              tableFields[index + 1]?.key,
                              inputFields[tableFields[index + 1]?.value] ||
                                inputFields[tableFields[index + 1]?.key] ||
                                ""
                            )
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell />
                        <TableCell />
                      </>
                    )}
                  </>
                ) : null}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KeyValueTable;
