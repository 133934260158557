import { colors } from "../../config/theme";

export const styles = {
  tableContainer: {
    overflowY: "auto",
    minHeight: "180px",
    maxHeight: "650px",
    borderTop: 0.5,
    borderBottom: 0.5,
    borderTopColor: colors.grey,
    botderBottomColor: colors.blackLight,
  },
  tableStyle: {
    minWidth: 650,
    border: 1,
    overflowY: "auto",
  },
  tableHeadStyle: {
    background: colors?.themeBg,
    borderColor: colors?.themeBg,
  },
  tableHeadCell: {
    textAlign: "left",
    color: colors?.fullWhite,
    borderColor: colors?.themeBg,
    backgroundColor: colors?.themeBg,
  },
  tableHeadCellAction: {
    textAlign: "left",
    minWidth: 80,
    paddingLeft: 1.5,
  },
  tableContentStyle: {},
  tableContentRow: { "&:last-child td, &:last-child th": { border: 0 } },
  tableCell: { textAlign: "left", color: colors?.textColor },
  tableCellProduct: { minWidth: 200, maxWidth: 280 },
  tableCellDate: { width: 180, paddingLeft: 0.5, paddingRight: 0.5 },
  tableCellStatus: {
    minWidth: 90,
    paddingLeft: 0.5,
    paddingRight: 0.5,
  },
  tableCellUser: { minWidth: 120, maxWidth: 180, paddingLeft: 1 },
  tableCellAction: {
    textAlign: "left",
    color: colors?.themeBg,
    minWidth: 80,
    paddingLeft: 0.5,
  },
  iconStyle: { color: colors?.themeBg, fontSize: "large" },
  pencilIconStyle: { borderBottom: 1, borderBottomColor: colors?.themeBg },
  iconContainerStyle: {
    display: "flex",
    flexDirection: "row",
    // justifyContent:'space-evenly',
    paddingLeft: 0.5,
    paddingRight: 0.5,
  },
  viewIconButton: { padding: 0.5 },
  editIconButton: { padding: 0.5, marginLeft: 2 },
  emptyDataCell: { p: 3 },
};

export const tableHeaderStyles = (item: string) => {
  switch (item) {
    case "ExecutionRunId":
      return [styles.tableHeadCell];
    case "ProductEvaluationExecutionRunName":
      return [styles.tableHeadCell, styles.tableCellProduct];
    case "StartTime":
      return [styles.tableHeadCell, styles.tableCellDate];
    case "EndTime":
      return [styles.tableHeadCell, styles.tableCellDate];
    case "Status":
      return [styles.tableHeadCell, styles.tableCellStatus];
    case "CreatedBy":
      return [styles.tableHeadCell, styles.tableCellUser];
    case "ModifiedBy":
      return [styles.tableHeadCell, styles.tableCellUser];
    case "Action":
      return [styles.tableHeadCell, styles.tableHeadCellAction];
    default:
      return [styles?.tableCellAction];
  }
};

export const tableCellStyles = (item: string) => {
  switch (item) {
    case "ExecutionRunId":
      return {};
    case "ProductEvaluationExecutionRunName":
      return styles.tableCellProduct;
    case "StartTime":
      return styles.tableCellDate;
    case "EndTime":
      return styles.tableCellDate;
    case "Status":
      return styles.tableCellStatus;
    case "CreatedBy":
      return styles.tableCellUser;
    case "ModifiedBy":
      return styles.tableCellUser;
    case "Action":
      return styles.tableHeadCellAction;
    default:
      return styles?.tableCell;
  }
};
