import { getToken } from "../../../utils/authToken";
import axios, { AxiosResponse, AxiosError } from "axios";

const instance = axios.create({
  // baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  timeout: 10000,
});

// Request interceptor
instance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = getToken();
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Response interceptor
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default instance;
