import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableSortLabel,
} from "@mui/material";
import { TitleConstants } from "../../constants/CommonConstants";
import {
  cellDataStyle,
  CommonTableStyles as styles,
  tableBodyStyles,
  tableHeaderStyles,
} from "./styles";
import { getvalue } from "../../stateManagement/actions/dashboardActions";
import MenuLIst from "./MenuLIst";
import { colors } from "../../config/theme";

interface IColumn {
  id: string;
  label: string;
}

interface IProps {
  columnData: IColumn[];
  rowsData: { [key: string]: any }[];
  mainLabel: string;
  totalRecords: number;
}

const CommonTableContainer: React.FC<IProps> = ({
  columnData,
  rowsData,
  mainLabel,
  totalRecords = 0,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rowsPerPage to 10
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>(""); // Initially no column is sorted

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getComparator = (order: "asc" | "desc", orderBy: string) => {
    return order === "desc"
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  // Apply sorting to the entire dataset
  const sortedRowsData = stableSort(rowsData, getComparator(order, orderBy));

  // Apply pagination to the sorted data
  const paginatedRows = sortedRowsData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // // don't remove it, Function to get paginated and sorted rows
  // const getVisibleRows = () => {
  //   // Calculate the start and end index based on the current page and rowsPerPage
  //   const startIndex = page * rowsPerPage;
  //   const endIndex = startIndex + rowsPerPage;
  //   // Extract the visible rows
  //   const visibleRows = rowsData.slice(startIndex, endIndex);
  //   // Apply sorting on the visible rows only
  //   const sortedVisibleRows = stableSort(
  //     visibleRows,
  //     getComparator(order, orderBy)
  //   );
  //   return sortedVisibleRows;
  // };

  const combinedStyles = (item: any) => {
    return {
      ...styles.sortedCellStyle,
      color: colors.fullWhite,
      "&:hover": {
        backgroundColor: colors?.themeBg,
        color: colors.fullWhite,
        border: 0,
        borderBottomWidth: 1,
        borderColor: colors.fullWhite,
      },
      "&:after": {
        content: '""',
        display: "block",
        borderBottom:
          orderBy === item.id ? `1px solid ${colors.fullWhite}` : "none",
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        color: colors.fullWhite,
      },
      "&:hover:after": {
        borderBottom:
          orderBy === item.id
            ? `1px solid ${colors.fullWhite}`
            : `1px solid ${colors.fullWhite}`,
        color: colors.fullWhite,
      },
      "& .MuiTableSortLabel-root": {
        color: colors.fullWhite,
      },
    };
  };

  return (
    <div style={styles.mainSection}>
      <div style={styles.titleContainer}>
        <Typography sx={styles.tableTitleStyle}>{mainLabel}</Typography>
        <MenuLIst columnData={columnData} rowsData={rowsData} />
      </div>
      <Box sx={styles.tableMainBox}>
        <TableContainer
          component={Paper}
          sx={[
            styles.tableContainer,
            rowsData?.length === 0 && styles.noRecordFound,
          ]}
        >
          <Table aria-label="simple table" sx={styles.tableStyle} stickyHeader>
            <TableHead sx={styles.tableHeadStyle}>
              <TableRow>
                {columnData.map((column: IColumn) => {
                  return (
                    <TableCell
                      key={column.id}
                      sortDirection={orderBy === column.id ? order : false}
                      // padding={"none"}
                      sx={tableHeaderStyles(column?.id, orderBy === column.id)}
                    >
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : "asc"}
                        onClick={() => handleRequestSort(column.id)}
                        sx={combinedStyles(column)}
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedRows.length > 0 ? (
                paginatedRows.map((row: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      {columnData.map((column) => {
                        return (
                          <TableCell
                            key={column?.id}
                            align={cellDataStyle(column?.id, row[column?.id])}
                            sx={tableBodyStyles(column?.id)}
                          >
                            {getvalue(column?.id, row[column?.id])}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow key={0}>
                  <TableCell
                    colSpan={columnData?.length}
                    align={"center"}
                    sx={styles.emptyDataCell}
                  >
                    {TitleConstants.NO_RECORDS}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          disabled={totalRecords > 10 || rowsData?.length > 0 ? false : true}
          rowsPerPageOptions={
            totalRecords > 10 || rowsData?.length > 10 ? [10, 30, 50] : []
          }
          component="div"
          count={totalRecords || rowsData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </div>
  );
};

export default CommonTableContainer;
