import React from "react";
import AppWrapper from "./routes/routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./stateManagement/store";
import { BrowserRouter as Router } from "react-router-dom";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <AppWrapper />
        </Router>
      </PersistGate>
    </Provider>
  );
};

export default App;
