/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "typeface-roboto";
import {
  TitleConstants,
  TokenConstants,
  NumericConstants,
  ValidationConstants,
  StorageKeys,
  ImagesAndLogos,
} from "../../../constants/CommonConstants";
import RouteConstants from "../../../constants/RouteConstants";
import {
  getTenantDetailsFailure,
  // incrementLoginAttempts,
  loginUser,
} from "../../../stateManagement/actions/securityActions";

import {
  Box,
  Button,
  Grid,
  // IconButton,
  // InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  showErrorMessage,
  validateEmail,
  encryptData,
  getEncryptedKey,
  validatePassword,
} from "../../../utils/CommonUtils";
import { UserTenantDetailsInterface } from "../../../TenantData";
import PushNotifications from "../../../components/PushNotifications";
import { setResetItemsInLocalStorage } from "../../../utils/authToken";
import { buttonStyles, styles } from "./styles";
import "./styles.css";
import LoadingOverlay from "../../../components/Loader/LoadingOverlay";
import { colors } from "../../../config/theme";
import ToastSectionLogin from "../../../components/Login/ToastSectionLogin";
import {
  AppDispatch,
  RootState,
} from "../../../stateManagement/services/rootDispatcher";
// import { Visibility, VisibilityOff } from "@mui/icons-material";

const Login = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigateToRoute = useNavigate();
  const location = useLocation();
  const [data, setData] = useState<boolean>();
  const [resetPage, setResetPage] = useState<boolean>();
  const [validationFails, setValidationFails] = useState<boolean>();
  const [resetSuccess, setResetSuccess] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState("");
  // const [loginFailure, setLoginFailure] = useState(false);
  const [isLoginApiCalled, setIsLoginApiCalled] = useState(false);

  const [
    {
      isLoginDisabled,
      isLoggedIn,
      tenantDetailSuccessMsg,
      tenantDetailFailMsg,
      loginLoader,
      loginAttempts,
      loginError,
      isUserSelectionSuccess,
      // successMessage,
    },
    prevousPageName,
  ]: any = useSelector((state: RootState) => {
    return [state?.securityReducer, state?.historyReducer?.prevousPageName];
  }, shallowEqual);

  const forgotToLogin: any = localStorage.getItem(StorageKeys.FORGET_TO_LOGIN);
  const forgotToLoginParse = JSON.parse(forgotToLogin);

  const resetToLogin: any = localStorage.getItem(StorageKeys.RESET_TO_LOGIN);
  const resetToLoginParse = JSON.parse(resetToLogin);

  const resetValidatorFails: any = localStorage.getItem(
    StorageKeys.RESET_VALIDATOR_TO_LOGIN
  );
  const resetValidatorFailsParse = JSON.parse(resetValidatorFails);

  const fetchData = useCallback(async () => {
    const time = setTimeout(() => {
      dispatch(getTenantDetailsFailure(null));
    }, NumericConstants.TOAST_AUTO_HIDE_SMALL_INTERVAL);
    return () => {
      clearTimeout(time);
    };
  }, []);

  useEffect(() => {
    setResetPage(resetValidatorFailsParse);
  }, [resetPage]);

  useEffect(() => {
    setData(forgotToLoginParse);
  }, [data]);

  useEffect(() => {
    setResetSuccess(resetToLoginParse);
  }, [resetSuccess]);

  useEffect(() => {
    setValidationFails(resetValidatorFailsParse);
  }, [validationFails]);

  const resetData = useCallback(async () => {
    setResetPage(resetValidatorFailsParse);
    setData(forgotToLoginParse);
    setResetSuccess(resetToLoginParse);
    // setValidationFails(resetValidatorFailsParse);
  }, [resetValidatorFailsParse, forgotToLoginParse, resetToLoginParse]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // setLoginFailure(!isLoggedIn);
    if (isLoginDisabled) {
      setErrorMessage(TitleConstants.ACCOUNT_LOCKED);
    } else {
      if (isLoginApiCalled && !isLoggedIn && loginError) {
        // dispatch(incrementLoginAttempts());
        // localStorage.removeItem("JWTToken");
        // setErrorMessage(ErrorConstant.INVALID_USERNAME_PASSWORD);
      } else if (isLoginApiCalled && isLoggedIn && !loginError) {
        // setIsTenantSelectionApiCalled(true);
        setResetItemsInLocalStorage(TokenConstants.TENANT_ID_KEY, undefined);
        setResetItemsInLocalStorage(TokenConstants.COMPANY_ID_KEY, undefined);
        // dispatch(getTenantDetailsAction());
      }
    }
  }, [isLoggedIn, loginAttempts, loginError]);

  useEffect(() => {
    if (tenantDetailSuccessMsg) {
      let userTenantDetails: UserTenantDetailsInterface[] =
        tenantDetailSuccessMsg.PayLoad
          .UserTenantDetails as UserTenantDetailsInterface[];
      if (userTenantDetails.length === 0) {
        showErrorWhenCurrentUserHasNoTenant();
      } else if (userTenantDetails.length === 1) {
        setResetItemsInLocalStorage(
          TokenConstants.TENANT_ID_KEY,
          JSON.stringify(userTenantDetails[0].TenantId)
        );
        setResetItemsInLocalStorage(
          TokenConstants.COMPANY_ID_KEY,
          JSON.stringify(userTenantDetails[0].CompanyId)
        );
        if (
          location?.pathname === RouteConstants.URL_LOGIN &&
          prevousPageName &&
          prevousPageName !== RouteConstants.URL_LOGIN
        ) {
          if (isUserSelectionSuccess) {
            navigateToRoute(prevousPageName, { replace: true });
          }
        } else {
          if (isUserSelectionSuccess) {
            navigateToRoute(RouteConstants.URL_HOME_PAGE, { replace: true });
          }
        }
      } else {
        navigateToRoute(RouteConstants.URL_TENANT_SELECTION, {
          state: { userTenantDetails: userTenantDetails },
        });
      }
    }
  }, [tenantDetailSuccessMsg, isUserSelectionSuccess]);

  useEffect(() => {
    if (tenantDetailFailMsg) {
      showErrorWhenCurrentUserHasNoTenant();
    }
  }, [tenantDetailFailMsg]);

  const showErrorWhenCurrentUserHasNoTenant = () => {
    // toast.error(
    //   TitleConstants.USER_HAS_NO_TENANT_ASSIGNED_SO_THE_USER_IS_SUSPENDED,
    //   {
    //     position: "top-right",
    //     autoClose: 8000,
    //     style: {
    //       color: "black",
    //       height: "60px",
    //       fontSize: "12px",
    //     },
    //   }
    // );
    resetData();
  };

  const submitLoginForm = async (data: any) => {
    setErrorMessage("");
    const encryptedEmail = encryptData(data.email, getEncryptedKey());
    const encryptedPassword = encryptData(data.password, getEncryptedKey());
    const encryptedUserCredentials = {
      username: encryptedEmail,
      password: encryptedPassword,
    };
    setIsLoginApiCalled(true);
    // dispatch(incrementLoginAttempts());
    dispatch(loginUser(encryptedUserCredentials));
  };

  return (
    <Box>
      {/* <ToastContainer /> */}
      <ToastSectionLogin />
      <LoadingOverlay isVisible={loginLoader} />
      <Grid container sx={styles.mainGridContainer}>
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={6}
          xs={12}
          margin={"auto"}
          sx={styles.imageBoxGridContainer}
        >
          <Box
            component="img"
            alt="Colleagues"
            src={ImagesAndLogos?.StockImage}
            sx={styles.imageBoxStyle}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12} margin={"auto"}>
          <form onSubmit={handleSubmit((data) => submitLoginForm(data))}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={styles?.boxGap}
              margin={styles?.boxMargin}
              padding={styles?.boxPadding}
              border={"1px solid #707070"}
            >
              <Typography
                color={colors?.purple}
                paddingLeft={6}
                sx={styles?.titleStyle}
              >
                {TitleConstants.LOGIN}
              </Typography>
              <Box sx={[styles?.inputContainer, styles?.emailInputContainer]}>
                <Typography sx={styles?.inputTitle}>
                  {TitleConstants.EMAIL}
                </Typography>
                <TextField
                  error={Boolean(errors.email)}
                  helperText={showErrorMessage(
                    Boolean(errors.email),
                    ValidationConstants.EMAIL_VALIDATION
                  )}
                  type={"text"}
                  sx={styles?.inputFieldStyle}
                  placeholder={""}
                  {...register("email", {
                    validate: (email) => validateEmail(email),
                  })}
                />
              </Box>
              <Box sx={[styles?.inputContainer]}>
                <Typography sx={[styles?.inputTitle]}>
                  {TitleConstants.PASSWORD}
                </Typography>
                <TextField
                  autoComplete="on"
                  error={Boolean(errors.password)}
                  helperText={showErrorMessage(
                    Boolean(errors.password),
                    ValidationConstants.ENTER_PASSWORD
                  )}
                  // type={showPassword?'text':"password"}
                  type={"password"}
                  sx={[styles?.inputFieldStyle]}
                  placeholder={""}
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment
                  //       position="end"
                  //       sx={{
                  //         height: "auto",
                  //         minWidth: 35,
                  //         padding: 0,
                  //         margin: 0,
                  //         alignItems: "center",
                  //       }}
                  //     >
                  //       <IconButton
                  //         aria-label="toggle password visibility"
                  //         onClick={handleClickShowPassword}
                  //         // onMouseDown={handleMouseDownPassword}
                  //         edge="end"
                  //         sx={{ alignSelf: "center" }}
                  //       >
                  //         {showPassword ? <VisibilityOff /> : <Visibility />}
                  //       </IconButton>
                  //     </InputAdornment>
                  //   ),
                  // }}
                  {...register("password", {
                    validate: (password) => validatePassword(password),
                  })}
                />
              </Box>
              <Button
                disabled={isLoginDisabled || loginLoader}
                sx={buttonStyles}
                type="submit"
              >
                {TitleConstants.LOGIN}
              </Button>
            </Box>
          </form>
          <Box sx={styles?.linkContainerBox}>
            <Link
              sx={styles?.linkLabelStyle}
              underline="hover"
              href={RouteConstants.URL_FORGOT_PASSWORD}
            >
              {TitleConstants.FORGOT_PASSWORD}
            </Link>
          </Box>
          <img
            src={ImagesAndLogos?.PORDUCT_LOGO}
            className="logoBottomRight lazyloaded"
            data-ll-status="loaded"
            alt="logo"
          />
        </Grid>
      </Grid>
      {errorMessage && errorMessage.length > 0 && (
        <PushNotifications
          message={errorMessage}
          severity={"error"}
          autoHide={NumericConstants.TOAST_AUTO_HIDE_INTERVAL}
        />
      )}
    </Box>
  );
};

export default Login;
