export const LOGIN_LOADER = "LOGIN_LOADER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const INCREMENT_LOGIN_ATTEMPTS = "INCREMENT_LOGIN_ATTEMPTS";

export const RESET_LOGIN_ATTEMPTS = "RESET_LOGIN_ATTEMPTS";

export const RESET_SECURITY_REQUEST = "RESET_SECURITY_REQUEST";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const LOGOUT = "LOGOUT";

export const RESET_VALIDATOR_SUCCESS = "RESET_VALIDATOR_SUCCESS";
export const RESET_VALIDATOR_FAILURE = "RESET_VALIDATOR_FAILURE";

export const TENANT_DETAILS_REQUEST = "TENANT_DETAILS_REQUEST";
export const GET_TENANT_DETAILS_SUCCESS = "GET_TENANT_DETAILS_SUCCESS";
export const GET_TENANT_DETAILS_FAILURE = "GET_TENANT_DETAILS_FAILURE";

export const GET_USER_DETAILS_REQUEST = "GET_USER_DETAILS_REQUEST";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";
export const GET_USER_DETAILS_FAILURE = "GET_USER_DETAILS_FAILURE";

export const LOADER_ACTION = "LOADER_ACTION";
export const GET_PRODUCT_EVALUATION_LIST_REQUEST =
  "GET_PRODUCT_EVALUATION_LIST_REQUEST";
export const GET_PRODUCT_EVALUATION_LIST_SUCCESS =
  "GET_PRODUCT_EVALUATION_LIST_SUCCESS";
export const GET_PRODUCT_EVALUATION_LIST_FAILURE =
  "GET_PRODUCT_EVALUATION_LIST_FAILURE";
export const GET_PRODUCT_EVALUATION_OUTPUT_SUCCESS =
  "GET_PRODUCT_EVALUATION_OUTPUT_SUCCESS";
export const GET_PRODUCT_EVALUATION_OUTPUT_FAILURE =
  "GET_PRODUCT_EVALUATION_OUTPUT_FAILURE";
export const RESET_PRODUCT_EVALUATION_DETAILS_REQUEST =
  "RESET_PRODUCT_EVALUATION_DETAILS_REQUEST";
export const RESET_PRODUCT_EVALUATION_RESULT_REQUEST =
  "RESET_PRODUCT_EVALUATION_RESULT_REQUEST";
export const PRODUCT_EVALUATION_PAGE_DATA =
  "PRODUCT_EVALUATION_PAGE_DATA";

export const CREATE_RUN_PAGE_LOADER = "CREATE_RUN_PAGE_LOADER";
export const GET_DOSAGE_FORM_DATA_REQUEST = "GET_DOSAGE_FORM_DATA_REQUEST";
export const GET_DOSAGE_FORM_DATA_SUCCESS = "GET_DOSAGE_FORM_DATA_SUCCESS";
export const GET_DOSAGE_FORM_DATA_FAILURE = "GET_DOSAGE_FORM_DATA_FAILURE";

export const SAVE_PRODUCT_EVALUATION_REQUEST =
  "SAVE_PRODUCT_EVALUATION_REQUEST";
export const SAVE_PRODUCT_EVALUATION_SUCCESS =
  "SAVE_PRODUCT_EVALUATION_SUCCESS";
export const SAVE_PRODUCT_EVALUATION_FAILURE =
  "SAVE_PRODUCT_EVALUATION_FAILURE";
export const RESET_PRODUCT_EVALUATION_REQUEST =
  "RESET_PRODUCT_EVALUATION_REQUEST";

export const SAVE_CURRENT_PAGE_DATA = "SAVE_CURRENT_PAGE_DATA";
export const SAVE_CURRENT_PAGE_NAME = "SAVE_CURRENT_PAGE_NAME";
export const RESET_ROUTE_DATA = "RESET_ROUTE_DATA";

export const RESET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_ERROR_LOGS = "SET_ERROR_LOGS";

export const GET_PRODUCT_EVALUATION_DETAILS_SUCCESS =
  "GET_PRODUCT_EVALUATION_DETAILS_SUCCESS";
export const GET_PRODUCT_EVALUATION_DETAILS_FAILURE =
  "GET_PRODUCT_EVALUATION_DETAILS_FAILURE";

export const GET_PRODUCT_EVALUATION_RESULT_SUCCESS =
  "GET_PRODUCT_EVALUATION_RESULT_SUCCESS";
export const GET_PRODUCT_EVALUATION_RESULT_FAILURE =
  "GET_PRODUCT_EVALUATION_RESULT_FAILURE";
