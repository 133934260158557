import { Box, Grid, Typography, Button } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import { TitleConstants } from "../../../constants/CommonConstants";
import { useLocation, useNavigate } from "react-router-dom";
import RouteConstants from "../../../constants/RouteConstants";
import CommonTableContainer from "../../../components/CommonTableContainer";
import { tableHeaderFields } from "../../../constants/TableColumnsDataConstants";
import {
  fetchProductEvaluationDetailAction,
  fetchProductEvaluationResultAction,
} from "../../../stateManagement/actions/dashboardActions";
import { useAppSelector } from "../../../hooks/hooks";
import { shallowEqual } from "react-redux";
import LoadingOverlay from "../../../components/Loader/LoadingOverlay";
import { RootState } from "../../../stateManagement/services/rootDispatcher";
import KeyValueTable from "../../../components/EvaluationResultOutput/KeyValueTable";
import { OutputPageStyles as styles } from "./styles";
import { saveRouteDataAction } from "../../../stateManagement/actions/routeActions";

const ProductEvaluationOutput: FC = () => {
  const location = useLocation();
  const receivedParams = location.state;
  const navigateToRoute = useNavigate();
  const [listLoading, productevaluationOutput, historyData] = useAppSelector(
    (state: RootState) => {
      return [
        state.dashboardReducer?.listLoading,
        state.dashboardReducer?.productevaluationOutput,
        state.historyReducer,
      ];
    },
    shallowEqual
  );

  const fetchCalledRef = useRef(false);
  const fetchData = React.useCallback(async () => {
    if (fetchCalledRef.current) return;
    fetchCalledRef.current = true;
    if (productevaluationOutput) {
      const dataToSave = {
        savedData: {
          ...historyData?.savedData,
          [RouteConstants.URL_PRODUCT_EVALUATION_OUTPUT]:
            productevaluationOutput,
        },
      };
      await saveRouteDataAction(dataToSave);
    } else {
      if (receivedParams?.ExecutionRunId) {
        fetchProductEvaluationResultAction(receivedParams?.ExecutionRunId)
          .then(async () => {
            const dataToSave = {
              savedData: {
                ...historyData?.savedData,
                [RouteConstants.URL_PRODUCT_EVALUATION_OUTPUT]:
                  productevaluationOutput,
              },
            };
            await saveRouteDataAction(dataToSave);
          })
          .catch((error) => {
            // Handle the error appropriately here
          });
      }
    }
    // eslint-disable-next-line
  }, [receivedParams?.ExecutionRunId, productevaluationOutput, historyData]);

  useEffect(() => {
    const handlePageLoad = () => {
      fetchData();
    };
    if (document.readyState === "complete") {
      // If the page is already loaded, call the handler immediately
      handlePageLoad();
    } else {
      // Otherwise, wait for the window load event
      window.addEventListener("load", handlePageLoad);
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("load", handlePageLoad);
      };
    }
  }, [fetchData]); //
  // useEffect(() => {
  //   fetchData();
  //   return () => {};
  // }, [fetchData]);

  const navigateToCreateRun = () => {
    fetchProductEvaluationDetailAction(receivedParams?.ExecutionDefinitionId)
      .then(() => {
        navigateToRoute(RouteConstants.URL_CREATE_NEW_RUN, {
          state: {},
        });
      })
      .catch((error) => {
        // Handle the error appropriately here
      });
  };

  return (
    <div style={styles.mainSection}>
      <Box sx={styles.mainBox}>
        <LoadingOverlay isVisible={listLoading} />
        <ToastContainer />
        <Grid item width="100%" height="100%">
          <div style={styles.userInputInfoBlock}>
            <Typography sx={styles.titleStyle}>
              {TitleConstants.PRODUCT_EVALUATION_RUN_INPUT_PROVIDED}
            </Typography>
            <KeyValueTable
              data={productevaluationOutput?.Input}
              additionalData={productevaluationOutput}
            />
          </div>
          <CommonTableContainer
            columnData={tableHeaderFields.FINAL_RESULT_RESULTS_COLUMNS}
            rowsData={productevaluationOutput?.FinalResults.list || []}
            totalRecords={0}
            mainLabel={TitleConstants.FINAL_RESULT}
          />
          <CommonTableContainer
            columnData={tableHeaderFields.TRADEMARK_RESULTS_COLUMNS}
            rowsData={productevaluationOutput?.ResultStep4?.list || []}
            totalRecords={0}
            mainLabel={TitleConstants.TRADEMARK_RESULTS}
          />
          <CommonTableContainer
            columnData={tableHeaderFields.FDB_RESULTS_COLUMNS}
            rowsData={productevaluationOutput?.ResultStep3.list || []}
            totalRecords={0}
            mainLabel={TitleConstants.FDB_RESULTS}
          />
          <CommonTableContainer
            columnData={tableHeaderFields.ORANGE_BOOK_RESULTS_COLUMNS}
            rowsData={productevaluationOutput?.ResultStep2.list || []}
            totalRecords={0}
            mainLabel={TitleConstants.ORANGE_BOOK_RESULTS}
          />
          <CommonTableContainer
            columnData={
              tableHeaderFields.FDA_OFF_PATENT_DRUGS_LIST_RESULTS_COLUMNS
            }
            rowsData={productevaluationOutput?.ResultStep1.list || []}
            totalRecords={0}
            mainLabel={TitleConstants.FDA_OFF_PATENT_DRUGS_LIST_RESULTS}
          />

          <Box sx={styles.buttonContainer}>
            <Button
              disabled={listLoading}
              onClick={() => {
                navigateToCreateRun();
              }}
              sx={[styles.buttonStyle, styles.editButtonStyle]}
              type="submit"
            >
              {TitleConstants.EDIT}
            </Button>
            <Button
              onClick={
                () =>
                  navigateToRoute(RouteConstants.URL_HOME_PAGE, {
                    replace: true,
                  })
                // navigateToRoute(-1)
              }
              sx={[styles.buttonStyle, styles.closeButtonStyle]}
            >
              {TitleConstants.CLOSE}
            </Button>
          </Box>
        </Grid>
      </Box>
    </div>
  );
};

export default ProductEvaluationOutput;
