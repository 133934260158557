export const settingComponentStyles = {
  paperContainer: {
    minWidth: { xs: "65%", sm: "35%", md: "35%", lg: "25%", xl: "15%" },
    maxWidth: { xs: "65%", sm: "35%", md: "35%", lg: "25%", xl: "25%" },
    mt: "70px",
    p: 2,
    borderRadius: 2,
    ml: 1,
  },
  avatarStyles: {
    width: { xs: 64, sm: 96, md: 128, lg: 128, xl: 128 },
    height: { xs: 64, sm: 96, md: 128, lg: 128, xl: 128 },
    mx: "auto",
  },
  dividerStyles: { pt: "10px", mb: "10px" },
  labelStyle: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: {
      xs: "10px",
      sm: "11px",
      md: "14px",
      lg: "17px",
      xl: "20px",
    },
  },
  valueStyles: {
    fontFamily: "Roboto",
    flexWrap: "wrap",
    fontSize: {
      xs: "8px",
      sm: "9px",
      md: "12px",
      lg: "15px",
      xl: "18px",
    },
  },
};
