// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toolbar {
    display: flex;
    justify-content: space-between;
    box-shadow: none;
    background-color: #fff;
  }
  
  .logoLeft {
    height: 50px; /* Adjust height as needed */
    width: auto;
    margin-right: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/CommonToolbar/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,gBAAgB;IAChB,sBAAsB;EACxB;;EAEA;IACE,YAAY,EAAE,4BAA4B;IAC1C,WAAW;IACX,kBAAkB;EACpB","sourcesContent":[".toolbar {\n    display: flex;\n    justify-content: space-between;\n    box-shadow: none;\n    background-color: #fff;\n  }\n  \n  .logoLeft {\n    height: 50px; /* Adjust height as needed */\n    width: auto;\n    margin-right: auto;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
