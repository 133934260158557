import { TokenConstants } from "../constants/CommonConstants";

export const setAuthToken = (JWTToken: string | undefined): void => {
  if (JWTToken) {
    // axios.defaults.headers.common[TokenConstants.TOKEN_AUTH_HEADER] = JWTToken;
    localStorage.setItem(TokenConstants.TOKEN_NAME, JSON.stringify(JWTToken));
  } else {
    // delete axios.defaults.headers.common[TokenConstants.TOKEN_AUTH_HEADER];
    localStorage.removeItem(TokenConstants.TOKEN_NAME);
  }
};

export const setResetItemsInLocalStorage = (
  key: string,
  value: string | undefined
) => {
  if (value) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(TokenConstants.TOKEN_NAME);
  return token ? JSON.parse(token) : null;
};

export const isTokenSameAsPervious = (newToken: string) => {
  const currenttoken = getToken();
  return newToken && newToken !== currenttoken;
};

export const updateTokenIfDifferent = (newToken: string | undefined) => {
  const currentToken = getToken();
  if (currentToken !== newToken) {
    setAuthToken(newToken);
  }
};
