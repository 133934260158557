import { colors } from "../../../config/theme";

export const buttonStyles = {
  margin: "auto",
  color: colors?.fullWhite,
  fontWeight: 700,
  fontSize: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
  },
  lineHeight: {
    xs: "17px",
    sm: "19px",
    md: "21px",
    lg: "23px",
    xl: "28px",
  },
  textTransform: "capitalize",
  backgroundColor: colors?.purple,
  borderRadius: "10px",
  padding: {
    xs: "2px 17px",
    sm: "4px 19px",
    md: "6px 21px",
    lg: "8px 23px",
    xl: "10px 25px",
  },
  fontFamily: "Roboto",
  border: "1px solid #176893",
  "&:hover": {
    backgroundColor: colors?.purpleLight,
  },
  "&.Mui-disabled": {
    background: colors?.grey,
    color: colors?.blackLight,
  },
};

export const styles = {
  imageContainer: {
    width: {
      xs: "80%",
      sm: "100%",
      md: "100%",
      lg: "100%",
      xl: "80%",
    },
    height: {
      xs: "80%",
      sm: "100%",
      md: "100%",
      lg: "100%",
      xl: "100%",
    },
  },
  imageContainerPadding: {
    xs: "0px 35px",
    sm: "0px 0px",
  },
  boxGap: {
    xs: "8px",
    sm: "10px",
    md: "12px",
    lg: "14px",
    xl: "16px",
  },
  boxMargin: {
    xs: "10px 10px",
    sm: "0px 10px",
    md: "0px 20px",
    lg: "0px 30px",
    xl: "0px 40px",
  },
  boxPadding: {
    xs: "5px 0px",
    sm: "15px 0px",
    md: "25px 0px",
    lg: "35px 0px",
    xl: "70px 0px",
  },
  titleStyle: {
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: {
      xs: "16px",
      sm: "17px",
      md: "19px",
      lg: "21px",
      xl: "24px",
    },
    lineHeight: {
      xs: "21.13px",
      sm: "23.13px",
      md: "25.13px",
      lg: "27.13px",
      xl: "28.13px",
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    pl: 6,
    pr: 6,
  },
  emailInputContainer: {
    mt: 2,
    mb: 2,
  },
  inputTitle: {
    marginBottom: "8px", // Space between label and text field
    color: colors?.fullBlack,
    fontFamil: "Roboto",
    fontWeight: "500",
    fontSize: {
      xs: "10px",
      sm: "11px",
      md: "14px",
      lg: "17px",
      xl: "20px",
    },
  },
  passInputTitle: {
    fontFamil: "Roboto",
    fontWeight: "500",
  },

  inputFieldStyle: {
    width: "100%", // Full width of the parent container
    margin: "0", // No margin to ensure alignment with label
    padding: "0", // No padding to ensure alignment with label
    fontSize: {
      xs: "10px",
      sm: "11px",
      md: "14px",
      lg: "17px",
      xl: "20px",
    },
    fontWeight: "400",
    fontFamily: "Inter",
    lineHeight: {
      xs: "10px",
      sm: "15px",
      md: "18px",
      lg: "21px",
      xl: "24.2px",
    },
  },
  linkContainerBox: {
    display: "flex",
    flexDirection: "row",
    margin: {
      xs: "6px 25px",
      sm: "7px 25px",
      md: "8px 30px",
      lg: "9px 35px",
      xl: "10px 40px",
    },
  },
  linkLabelStyle: {
    fontFamily: "Inter",
    fontSize: {
      xs: "14px",
      sm: "14px",
      md: "16px",
      lg: "18px",
      xl: "20px",
    },
    fontWeight: "400",
    color: colors?.skyBlue,
    lineHeight: {
      xs: "8px",
      sm: "10px",
      md: "12px",
      lg: "14px",
      xl: "16px",
    },
    padding: {
      xs: "2px 0px",
      sm: "4px 0px",
      md: "6px 0px",
      lg: "8px 0px",
      xl: "10px 0px",
    },
    textDecoration: "none", // Remove underline by default
  },
  mainGridContainer: {
    // overflow:'auto',
    overflowY: "auto",
    width: "100%",
    height: "100vh", // 100vh for full viewport height
    display: "flex", // ensure container behaves as a flex container
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  imageBoxGridContainer: {
    width: {
      xs: "100%", // Full width for extra small screens
      sm: "80%", // 80% width for small screens
      md: "70%", // 70% width for medium screens
      lg: "60%", // 60% width for large screens
      xl: "50%", // 50% width for extra large screens
    },
    height: "600px", // Fixed height
    maxWidth: "100%", // Ensure max width is 100% to prevent overflow
  },
  imageBoxStyle: {
    width: "100%", // Full width of the parent container
    height: "100%", // Full height of the parent container
    objectFit: "cover", // Ensures the image covers the area while maintaining aspect ratio
  },
};
