import Login from "./auth/Login";
import ForgotPassword from "./auth/ForgotPassword";
import Home from "./dashboard/Home";
import CreateNewRun from "./dashboard/CreateNewRun";
import Settings from "./dashboard/Settings";
import ProductEvaluationOutput from "./dashboard/ProductEvaluationOutput";

export {
  Login,
  ForgotPassword,
  Home,
  CreateNewRun,
  Settings,
  ProductEvaluationOutput,
};
