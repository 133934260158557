import { composeWithDevTools } from "@redux-devtools/extension";
import rootReducer from "./reducers";
import { RootState } from "./services/rootDispatcher";
import {
  legacy_createStore as createStore,
  applyMiddleware,
  Middleware,
} from "redux";
import { persistStore, persistReducer, Persistor } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { AnyAction, MiddlewareAPI, Dispatch } from "redux";
import tokenExpiryMiddleware from "./middleWare/tokenExpiryMiddleware";

const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["securityReducer"],
  debug: true,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggingMiddleware: Middleware<{}, RootState> =
  (store: MiddlewareAPI<Dispatch<AnyAction>, RootState>) =>
  (next) =>
  (action: AnyAction) => {
    const result = next(action);
    return result;
  };

const middleware = [
  thunk as ThunkMiddleware<RootState, AnyAction>,
  loggingMiddleware,
  tokenExpiryMiddleware,
];

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persistor: Persistor = persistStore(store);

export default store;
