import { FieldError, Merge, FieldErrorsImpl } from "react-hook-form";
// import {
//   rulesListColor,
//   updateRulesListColor,
// } from "../../../stateManagement/actions/securityActions";
import { resetPasswordPageStyles as styles } from "./resetPasswordStyles";
import { List, ListItem } from "@mui/material";
import { ValidationConstants } from "../../../constants/CommonConstants";
import { colors } from "../../../config/theme";

export let rulesListColor = {
  minLength: "",
  maxLength: "",
  upperLowerCase: "",
  numeric: "",
  special: "",
};

export const updateRulesListColor = (color: string = colors?.fullBlack) => {
  rulesListColor.minLength = color;
  rulesListColor.maxLength = color;
  rulesListColor.upperLowerCase = color;
  rulesListColor.numeric = color;
  rulesListColor.special = color;
  return rulesListColor;
};

const RenderPasswordRequirements = (
  passwordValue:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined,
  color: string = "0xfff"
): JSX.Element => {
  updateRulesListColor(color);
  let inputsTagColor: string = color;
  if (passwordValue) {
    let message = passwordValue.message?.toString();
    switch (message) {
      case "NEW_PASSWORD_REQUIRED":
      case "CONFIRM_NEW_PASSWORD_REQUIRED":
        rulesListColor.minLength = colors?.red;
        break;
      case "MIN_PASSWORD_REQUIREMENT":
        rulesListColor.minLength = colors?.red;
        break;
      case "MAX_PASSWORD_REQUIREMENT":
        rulesListColor.maxLength = colors?.red;
        break;
      case "UPPER_LOWER_CASE_VALIDATION":
        inputsTagColor = colors?.red;
        rulesListColor.upperLowerCase = colors?.red;
        break;
      case "NUMERIC_VALIDATION":
        inputsTagColor = colors?.red;
        rulesListColor.numeric = colors?.red;
        break;
      case "SPECIAL_CHAR_VALIDATION":
        inputsTagColor = colors?.red;
        rulesListColor.special = colors?.red;
        break;
      case "SPACE_DETECTED":
        inputsTagColor = colors?.red;
        rulesListColor.special = colors?.red;
        break;
    }
  }
  return (
    <List dense={true} sx={styles.listContainer}>
      <ListItem sx={{ color: `${rulesListColor.maxLength}` }}>
        {ValidationConstants.PASSWORD_MAX_LENGTH}
      </ListItem>
      <ListItem sx={{ color: `${rulesListColor.minLength}` }}>
        {ValidationConstants.PASSWORD_MIN_LENGTH}
      </ListItem>
      <ListItem sx={{ color: `${inputsTagColor}` }}>
        {ValidationConstants.PASSWORD_INPUTS}
      </ListItem>
      <List dense={true} sx={styles.nestedListContainer}>
        <ListItem sx={{ color: `${rulesListColor.upperLowerCase}` }}>
          {ValidationConstants.PASSWORD_INPUT_CASE_RULE}
        </ListItem>
        <ListItem sx={{ color: `${rulesListColor.numeric}` }}>
          {ValidationConstants.PASSWORD_INPUT_NUMERAL_RULE}
        </ListItem>
        <ListItem sx={{ color: `${rulesListColor.special}` }}>
          {ValidationConstants.PASSWORD_INPUT_SPECIAL_CHARACTER_RULE}{" "}
          [!@#$%^&*()_-[]
          {}
          &lt;&gt;?~/\|]
        </ListItem>
      </List>
    </List>
  );
};

export default RenderPasswordRequirements;
