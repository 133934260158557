import {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  FC,
} from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "typeface-roboto";
import {
  ControllerNames,
  Keys,
  TitleConstants,
  ValidationConstants,
} from "../../../constants/CommonConstants";
import RouteConstants from "../../../constants/RouteConstants";

import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  showErrorMessage,
  validateFieldNotEmpty,
} from "../../../utils/CommonUtils";
import DeleteIcon from "@mui/icons-material/Cancel";
import TextFieldLabel from "../../../components/TextFieldLabel";
import { shallowEqual } from "react-redux";
import {
  getDosageFormData,
  saveProductEvaluationAction,
} from "../../../stateManagement/actions/createNewRunActions";
import {
  MenuProps,
  StyledTextField,
  getStyles,
  createRunStyles as styles,
} from "./styles";
import { useAppSelector } from "../../../hooks/hooks";
import ToastSection from "../../../components/CreateNewRun/ToastSection";
import LoadingOverlay from "../../../components/Loader/LoadingOverlay";
import { colors } from "../../../config/theme";
import { RootState } from "../../../stateManagement/services/rootDispatcher";

interface Props {}

const CreateNewRun: FC<Props> = (props: any) => {
  const theme = useTheme();
  const location = useLocation();
  // const receivedParams = location.state;
  const navigateToRoute = useNavigate();
  const [dosageFormList, requestLoader, requestData] = useAppSelector(
    (state: RootState) => {
      return [
        state.createNewRunReducer?.dosageFormList,
        state.createNewRunReducer?.requestLoader,
        state.createNewRunReducer?.requestData,
      ];
    },
    shallowEqual
  );

  const [nameList, setnameList] = useState<any[]>([]);
  const [selectedItemList, setSelectedItemList] = useState<any[]>(
    requestData?.SelectedDosageForms || []
  );
  const [isTyping, setIsTyping] = useState(false);
  const [tags, setTags] = useState<any>(
    requestData?.TrademarkIgnoreBrandList || []
  );
  const [isButtonDisable, setButtonDisable] = useState<boolean>(false);
  const fetchCalledRef = useRef(false);

  const fetchData = useCallback(async () => {
    if (fetchCalledRef.current) return;
    fetchCalledRef.current = true;
    getDosageFormData();
    setTags(requestData?.TrademarkIgnoreBrandList || []);
    setSelectedItemList(requestData?.SelectedDosageForms || []);
  }, [requestData]);

  useLayoutEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (dosageFormList && dosageFormList?.ReferenceDataList) {
      setnameList(dosageFormList?.ReferenceDataList);
    }
    return () => {};
  }, [dosageFormList]);

  const navigateBackToHome = () => {
    navigateToRoute(RouteConstants.URL_HOME_PAGE, { replace: true });
  };

  const submitCreateNewRunForm = async (data: any) => {
    setButtonDisable(true);
    if (requestData?.ExecutionDefinitionId) {
      data.ExecutionDefinitionId = requestData?.ExecutionDefinitionId || "";
    }
    saveProductEvaluationAction(data, location?.pathname)
      .then(() => {
        setTimeout(() => {
          setButtonDisable(false);
        }, 2000);
        navigateToRoute(RouteConstants.URL_HOME_PAGE, { replace: true });
      })
      .catch(() => {
        setButtonDisable(false);
      });
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { errors, isSubmitted },
  } = useForm();

  const handleKeyDown = (e: any) => {
    setIsTyping(true);
    if (e.key === Keys?.ENTER && e.target.value.trim()) {
      const newTag = e.target.value.trim();
      const newTags = [...tags, newTag];
      setTags(newTags);
      setValue(ControllerNames.TAGS, newTags);
      e.target.value = "";
      e.preventDefault();
      setIsTyping(false);
      trigger(ControllerNames.TAGS); // Manually trigger validation
    }
  };

  const handleDelete = (tagToDelete: any) => {
    const newTags = tags.filter((tag: any) => tag !== tagToDelete);
    setTags(newTags);
    setValue(ControllerNames.TAGS, newTags);
    trigger(ControllerNames.TAGS); // Manually trigger validation
  };

  const handleInputChange = (e: any) => {
    setIsTyping(true);
    if (e.target.value.trim() === "") {
      trigger(ControllerNames.TAGS); // Manually trigger validation when input is empty
    }
  };

  const handleBlur = () => {
    setIsTyping(false);
    trigger(ControllerNames.TAGS); // Manually trigger validation on blur
  };

  return (
    <div style={styles?.mainContainer}>
      <Box sx={{ mt: 0 }}>
        <LoadingOverlay isVisible={requestLoader} />
        <ToastSection />

        <Grid item width="100%" height="100%">
          <form onSubmit={handleSubmit((data) => submitCreateNewRunForm(data))}>
            <Box sx={styles.formBox}>
              <Typography color={colors?.purple} sx={styles?.titleTagStyle}>
                {TitleConstants.CREATE_A_NEW_RUN}
              </Typography>
              <Box sx={styles?.inputContainer}>
                <TextFieldLabel
                  label={TitleConstants.NAME_OF_THE_PRODUCT_EVALUATION_RUN}
                />
                <TextField
                  defaultValue={requestData?.ExecutionDefinitionName}
                  error={Boolean(errors.ExecutionDefinitionName)}
                  helperText={showErrorMessage(
                    Boolean(errors.ExecutionDefinitionName),
                    ValidationConstants.PLEASE_ENTER_NAME_OF_THE_PRODUCT_EVALUATION_RUN
                  )}
                  type={"text"}
                  inputProps={{
                    maxLength: 150,
                    sx: styles?.inputFieldStyle,
                  }}
                  sx={styles?.inputStyle}
                  placeholder={""}
                  {...register("ExecutionDefinitionName", {
                    validate: (ExecutionDefinitionName) =>
                      validateFieldNotEmpty(ExecutionDefinitionName),
                  })}
                />
              </Box>
              <Box sx={styles?.inputContainer}>
                <TextFieldLabel label={TitleConstants.PREFERRED_DOSAGE_FORM} />
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(errors.SelectedDosageForms)}
                >
                  <Controller
                    name={ControllerNames.PREFERRED_DOSAGE_FORM}
                    control={control}
                    defaultValue={selectedItemList || []}
                    rules={{
                      required:
                        ValidationConstants.PLEASE_SELECT_PREFERRED_DOSAGE_FORM,
                    }}
                    render={({ field }) => {
                      return (
                        <Select
                          multiple
                          displayEmpty
                          value={field?.value}
                          // onChange={field.onChange}
                          onChange={(event) => {
                            const selectedIds = event?.target?.value;
                            const selectedItems =
                              nameList.filter((item: any) =>
                                selectedIds.includes(item.Id)
                              ) || [];
                            // Merge new selections with existing selections
                            const newSelection = selectedItems.filter(
                              (selectedItem: any) =>
                                !field.value.some(
                                  (value: any) => value?.Id === selectedItem?.Id
                                )
                            );
                            field.onChange([...field?.value, ...newSelection]);
                          }}
                          input={<OutlinedInput />}
                          renderValue={(selected) => (
                            <Box sx={styles?.selectedItemListStyle}>
                              {selected?.length > 0 &&
                                selected.map((value: any, index: number) => {
                                  return (
                                    <Chip
                                      key={`${value?.Id}_${index}`}
                                      label={value?.Name}
                                      onMouseDown={(event) => {
                                        event.stopPropagation();
                                      }}
                                      onDelete={() => {
                                        field.onChange(
                                          field.value.filter(
                                            (v: any) => v !== value
                                          )
                                        );
                                      }}
                                      deleteIcon={<DeleteIcon />}
                                      sx={styles?.selectText}
                                    />
                                  );
                                })}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                          sx={styles?.selectText}
                        >
                          <MenuItem disabled value="">
                            <Typography sx={styles.menuTitleLabel}>
                              {TitleConstants.PREFERRED_DOSAGE_FORM}
                            </Typography>
                          </MenuItem>
                          {nameList.map((item: any, index: number) => {
                            return (
                              <MenuItem
                                key={`${item?.Id}_${index}`}
                                value={item?.Id}
                                style={getStyles(item, field.value, theme)}
                                selected={field.value.some(
                                  (selected: { Id: any }) =>
                                    selected.Id === item.Id
                                )}
                              >
                                {item?.Name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      );
                    }}
                  />
                  {errors.SelectedDosageForms && (
                    <FormHelperText error sx={styles?.selecterHelpTextStyle}>
                      {ValidationConstants.PLEASE_SELECT_PREFERRED_DOSAGE_FORM}
                    </FormHelperText>
                  )}
                </FormControl>
              </Box>
              <Controller
                name={"FDAOffPatentFreshDataDownloadBl"}
                control={control}
                defaultValue={
                  requestData?.FDAOffPatentFreshDataDownloadBl || false
                }
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      ml: 5,
                      mr: 5,
                    }}
                    control={<Checkbox {...field} checked={field.value} />}
                    label={
                      <Typography sx={styles.checkBoxlabel}>
                        {TitleConstants.FETCH_NEW_DATASET_FROM_FDA}
                      </Typography>
                    }
                  />
                )}
              />
              <Box sx={styles?.inputContainer}>
                <TextFieldLabel
                  label={
                    TitleConstants.MAXIMUM_RX_COUNT_PREFERENCE_IN_ORANGE_BOOK
                  }
                />
                <TextField
                  error={Boolean(errors.OrangeBookMaxRXCount)}
                  helperText={showErrorMessage(
                    Boolean(errors.OrangeBookMaxRXCount),
                    ValidationConstants.PLEASE_ENTER_MAXIMUM_RX_COUNT_PREFERENCE_IN_ORANGE_BOOK
                  )}
                  defaultValue={requestData?.OrangeBookMaxRXCount || 0}
                  type={"number"}
                  inputProps={{
                    min: 0,
                    max: 10,
                    sx: styles?.inputFieldStyle,
                  }}
                  sx={styles?.inputStyle}
                  placeholder={""}
                  {...register("OrangeBookMaxRXCount", {
                    validate: (OrangeBookMaxRXCount) =>
                      validateFieldNotEmpty(OrangeBookMaxRXCount),
                  })}
                />
              </Box>
              <Controller
                name={ControllerNames.ORABGE_BOOK_FRESH_DATA}
                control={control}
                defaultValue={
                  requestData?.OrangeBookFreshDataDownloadBl || false
                }
                render={({ field }) => (
                  <FormControlLabel
                    sx={{
                      ml: 5,
                      mr: 5,
                    }}
                    control={<Checkbox {...field} checked={field.value} />}
                    label={
                      <Typography sx={styles.checkBoxlabel}>
                        {TitleConstants.FETCH_NEW_ORANGE_BOOK}
                      </Typography>
                    }
                  />
                )}
              />
              <Box sx={styles?.inputContainer}>
                <TextFieldLabel
                  label={
                    TitleConstants.MAXIMUM_COUNT_PREFERENCE_FROM_ANALYSOURCE
                  }
                />
                <TextField
                  error={Boolean(errors.AnalysourceMaxActiveCount)}
                  helperText={showErrorMessage(
                    Boolean(errors.AnalysourceMaxActiveCount),
                    ValidationConstants.PLEASE_ENTER_MAXIMUM_COUNT_PREFERENCE_FROM_ANALY_SOURCE
                  )}
                  defaultValue={requestData?.AnalysourceMaxActiveCount || 0}
                  type={"number"}
                  inputProps={{
                    min: 0,
                    max: 10,
                    sx: styles?.inputFieldStyle,
                  }}
                  sx={styles?.inputStyle}
                  placeholder={""}
                  {...register("AnalysourceMaxActiveCount", {
                    validate: (AnalysourceMaxActiveCount) =>
                      validateFieldNotEmpty(AnalysourceMaxActiveCount),
                  })}
                />
              </Box>
              <Box sx={styles?.inputContainer}>
                <TextFieldLabel
                  label={
                    TitleConstants.NOT_SUPPORTED_PRODUCT_OWNERS_FOR_TRADEMARK
                  }
                />
                <Controller
                  name={ControllerNames.TAGS}
                  control={control}
                  defaultValue={tags || []}
                  //rules={{ validate: (value) => value?.length > 0 || ValidationConstants.AT_LEAST_ONE_TAG_IS_REQUIRED }}
                  render={({ field, fieldState: { error } }) => (
                    <Box>
                      <StyledTextField
                        onKeyDown={handleKeyDown}
                        onChange={(e) => {
                          field.onChange(e);
                          handleInputChange(e);
                        }}
                        onBlur={handleBlur}
                        error={!!error && (isSubmitted || !isTyping)}
                        InputProps={{
                          startAdornment:
                            tags &&
                            tags.map((tag: any, index: any) => (
                              <Chip
                                key={`${tag?.id}_${index}`}
                                label={tag}
                                onDelete={() => handleDelete(tag)}
                                sx={{ margin: "2px" }}
                              />
                            )),
                        }}
                        fullWidth
                      />
                      {error && (isSubmitted || !isTyping) && (
                        <FormHelperText sx={styles?.helpTextStyle} error>
                          {error.message}
                        </FormHelperText>
                      )}
                    </Box>
                  )}
                />
              </Box>
              <Box sx={styles?.buttonContainer}>
                <Button
                  disabled={requestLoader || isButtonDisable}
                  sx={styles?.buttonStyle}
                  type="submit"
                >
                  {TitleConstants.SUBMIT}
                </Button>
                <Button
                  onClick={() => navigateBackToHome()}
                  sx={{ ...styles?.buttonStyle, ml: 2 }}
                >
                  {TitleConstants.CANCEL}
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      </Box>
    </div>
  );
};

export default CreateNewRun;
