import store from "../store";
import {
  RESET_ROUTE_DATA,
  SAVE_CURRENT_PAGE_DATA,
  SAVE_CURRENT_PAGE_NAME,
} from "../types";

export const saveRouteDataAction = (data: any) => {
  const currentHistoryState = store.getState().historyReducer;
  return new Promise<void>(async (resolve, reject) => {
    try {
      data = {
        ...currentHistoryState,
        ...data,
      };
      if (data?.prevousPageName) {
        store.dispatch(saveRouteData(data?.prevousPageName));
      }
      if (data?.savedData) {
        store.dispatch(savePageData(data?.savedData));
      }
      resolve();
    } catch (error) {
      console.warn("error in save history[]:", error);
      reject();
    }
  });
};

export const savePageData = (data: any) => ({
  type: SAVE_CURRENT_PAGE_DATA,
  payload: data,
});

export const saveRouteData = (data: any) => ({
  type: SAVE_CURRENT_PAGE_NAME,
  payload: data,
});

export const resetRouteData = () => {
  return {
    type: RESET_ROUTE_DATA,
  };
};
