import { Dispatch } from "redux";
import {
  productEvaluationDetailService,
  productEvaluationListService,
  productEvaluationResultService,
} from "../services/dashboardServices";
import {
  GET_PRODUCT_EVALUATION_DETAILS_FAILURE,
  GET_PRODUCT_EVALUATION_DETAILS_SUCCESS,
  GET_PRODUCT_EVALUATION_LIST_FAILURE,
  GET_PRODUCT_EVALUATION_LIST_REQUEST,
  GET_PRODUCT_EVALUATION_LIST_SUCCESS,
  GET_PRODUCT_EVALUATION_OUTPUT_FAILURE,
  GET_PRODUCT_EVALUATION_OUTPUT_SUCCESS,
  LOADER_ACTION,
  PRODUCT_EVALUATION_PAGE_DATA,
  RESET_PRODUCT_EVALUATION_DETAILS_REQUEST,
} from "../types";
import store from "../store";
import { saveProductEvaluationRequest } from "./createNewRunActions";
import { rearrangeData } from "../dataResolver";
import {
  convertUnicodeEscapeSequence,
  isUnicodeEscapeSequence,
} from "../../utils/CommonUtils";
import { splitByIndex } from "../../components/TextLabelComponent/SplitingText";
// import { splitByComma } from "../../components/TextLabelComponent/SplitingText";

export const updatePageData = () => {
  const getPageData = store.getState()?.dashboardReducer?.pageData;
  const data = {
    pageNumber: getPageData?.pageNumber || 1,
    pageSize: getPageData?.pageSize || 10,
  };
  store.dispatch(fetchProductEvaluationList(data));
};

export const fetchProductEvaluationList = (payload: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(productEvaluationRequest());
    try {
      const data = await productEvaluationListService(payload);
      dispatch(productEvaluationListSuccess(data?.PayLoad));
      productEvaluationPageData(payload);
    } catch (error: any) {
      dispatch(productEvaluationListFailure(error.message));
    } finally {
      stopLoader();
    }
  };
};

export const fetchProductEvaluationDetailAction = (
  payload: any
): Promise<void> => {
  startLoader();
  return new Promise<void>(async (resolve, reject) => {
    resetProductEvaluationDetailRequest();
    try {
      const data = await productEvaluationDetailService(payload);
      store.dispatch(productEvaluationDetailSuccess(data));
      const newData: any = rearrangeData(data?.PayLoad);
      store.dispatch(saveProductEvaluationRequest(newData));
      resolve();
    } catch (error: any) {
      store.dispatch(
        productEvaluationDeatilFailure(
          error?.StatusMessage || error?.statusText || error?.message
        )
      );
      reject(error);
    } finally {
      stopLoader();
    }
  });
};

export const fetchProductEvaluationResultAction = (
  payload: any
): Promise<void> => {
  startLoader();
  return new Promise<void>(async (resolve, reject) => {
    resetProductEvaluationDetailRequest();
    try {
      const data: any = await productEvaluationResultService(payload);
      store.dispatch(
        productEvaluationOutputSuccess(data?.PayLoad || data?.payload)
      );
      resolve();
    } catch (error: any) {
      store.dispatch(
        productEvaluationOutputFailure(
          error?.StatusMessage || error?.statusText || error?.message
        )
      );
      reject(error);
    } finally {
      stopLoader();
    }
  });
};

export const formatValue = (key: any, value: any) => {
  try {
    switch (key) {
      case "FDAOffPatentFreshDataDownloadBl":
      case "OrangeBookFreshDataDownloadBl":
        if (typeof value === "string") {
          return value.toLocaleLowerCase() === "true" ? "Yes" : "No";
        } else {
          return value ? "Yes" : "No";
        }
      case "TrademarkIgnoreBrandList":
        if (value && Array.isArray(value)) {
          return value.join(", ");
        } else if (value && typeof value === "string") {
          // const data = value.replace(/'/g, '"');
          // Escape single quotes within string values
          const data = value.replace(/'([^']+)'/g, '"$1"');
          const convert = JSON.parse(data);
          return convert;
        } else {
          return value;
        }
      case "SelectedDosageForms":
        if (value && Array.isArray(value)) {
          return value
            .filter((ele) => ele?.Name)
            .map((item: any) => item?.Name);
        } else if (value && typeof value === "string") {
          // Replace single quotes around keys and string values, but not within the values themselves
          value = value.replace(/([{,]\s*)'([^']+?)'\s*:/g, '$1"$2":'); // match single quotes around keys
          value = value.replace(/:\s*'([^']+?)'\s*([,}])/g, ': "$1"$2'); // match single quotes around value

          // Replace True and False (standalone)
          value = value.replace(/\bTrue\b/g, "true"); //'\b' ensures that only standalone will convert and preventing accidental replacements within other words
          value = value.replace(/\bFalse\b/g, "false");
          const convert = JSON.parse(value);
          const data = convert.map((item: any) => item?.Name);
          return data;
        } else {
          return String(value);
        }
      default:
        return value;
    }
  } catch (error) {
    console.warn(`Error parsing JSON for key ${key}:`, error);
    return value;
  }
};

export const actionValue = (key: any, value: any) => {
  try {
    switch (key) {
      case "ActiveInSourceBl":
        if (typeof value === "boolean") {
          return value ? "Yes" : "No";
        } else if (typeof value === "number") {
          return value === 1 ? "Yes" : value === 0 ? "No" : "";
        } else {
          return "";
        }
      default:
        return value;
    }
  } catch (error) {
    console.error(`Error parsing JSON for key ${key}:`, error);
  }
};

export const getLabellerNamesValue = (key: any, value: any) => {
  try {
    if (value.includes("|")) {
      const splitLabellers = value.split("|");
      const formattedLabellers = splitByIndex(splitLabellers);
      return formattedLabellers;
    } else {
      return value;
    }
  } catch (error) {
    console.error(`Error parsing JSON for key ${key}:`, error);
  }
};

export const getvalue = (key: any, value: any) => {
  let cellValue: string = "";
  switch (key) {
    case "ActiveInSourceBl":
      cellValue = actionValue(key, value);
      break;
    case "LabellerNames":
      cellValue = getLabellerNamesValue(key, value);
      break;

    default:
      cellValue = getCellValue(value);
      break;
  }
  return cellValue;
};

export const getCellValue = (data: any) => {
  if (typeof data === "string") {
    if (isUnicodeEscapeSequence(data)) {
      return convertUnicodeEscapeSequence(data);
    } else {
      // return splitByComma(data);
      return data;
    }
  } else if (typeof data === "boolean") {
    return data ? "1" : "0";
  } else {
    return data;
  }
};

export const productEvaluationPageData = (data?: any) =>
  store.dispatch({
    type: PRODUCT_EVALUATION_PAGE_DATA,
    payload: data,
  });

export const productEvaluationRequest = () => ({
  type: GET_PRODUCT_EVALUATION_LIST_REQUEST,
});

export const productEvaluationListSuccess = (data: any) => ({
  type: GET_PRODUCT_EVALUATION_LIST_SUCCESS,
  payload: data,
});

export const productEvaluationListFailure = (error: string) => ({
  type: GET_PRODUCT_EVALUATION_LIST_FAILURE,
  payload: error,
});

export const productEvaluationOutputSuccess = (data: any) => ({
  type: GET_PRODUCT_EVALUATION_OUTPUT_SUCCESS,
  payload: data,
});

export const productEvaluationOutputFailure = (error: string) => ({
  type: GET_PRODUCT_EVALUATION_OUTPUT_FAILURE,
  payload: error,
});

export const productEvaluationDetailSuccess = (data: any) => ({
  type: GET_PRODUCT_EVALUATION_DETAILS_SUCCESS,
  payload: data,
});

export const productEvaluationDeatilFailure = (error: string) => ({
  type: GET_PRODUCT_EVALUATION_DETAILS_FAILURE,
  payload: error,
});

export const startLoader = () =>
  store.dispatch({ type: LOADER_ACTION, payload: true });

export const stopLoader = () =>
  store.dispatch({ type: LOADER_ACTION, payload: false });

export const resetProductEvaluationDetailRequest = () =>
  store.dispatch({ type: RESET_PRODUCT_EVALUATION_DETAILS_REQUEST });
