import React from "react";

export const splitByComma = (text: string) => {
  if (text && text.length > 0 && text.includes(",")) {
    const parts = text.split(",");
    return parts.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < parts.length - 1 && ","}
        <br />
      </React.Fragment>
    ));
  } else {
    return text;
  }
};

export const splitLines = (text: string) => {
  return text.split("\n").map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
};

export const splitByIndex = (list: any) => {
  if (list) {
    if (Array.isArray(list) && list.length > 0) {
      return list.map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      return String(list);
    }
  } else {
    return '';
  }
};
