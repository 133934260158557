import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  NumericConstants,
  TitleConstants,
  ErrorConstant,
} from "../../constants/CommonConstants";
import { resetPasswordAction } from "../../stateManagement/actions/securityActions";
import { encryptData, getEncryptedKey } from "../../utils/CommonUtils";
import {
  validateAtleastNumericChar,
  validateAtleastUpperLowerCase,
  validateAtleastSpecialChar,
} from "../../utils/CommonUtils";
import { colors } from "../../config/theme";
import { resetPasswordPageStyles as styles } from "./ResetPasswordComponents/resetPasswordStyles";
import LoadingOverlay from "../Loader/LoadingOverlay";
import CheckAndSetError from "./ResetPasswordComponents/CheckAndSetError";
import RenderPasswordRequirements from "./ResetPasswordComponents/RenderPasswordRequirements";
import { AppDispatch, RootState } from "../../stateManagement/services/rootDispatcher";

interface ResetPasswordProps {
  token: string | null;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ token }) => {
  const dispatch: AppDispatch = useDispatch();
  const navigateToRoute = useNavigate();

  const [loginLoader, resetSuccess]: any = useSelector((state: RootState) => {
    return [
      state?.securityReducer?.loginLoader,
      state?.securityReducer?.resetMessage,
    ];
  }, shallowEqual);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
  });
  const [passwordRequirementsColor, setPasswordRequirementsColor] = useState(
    colors?.fullBlack
  );

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const password = watch("newPassword", "");

  const onSubmit = async (data: any) => {
    const encryptedNewPassword = encryptData(
      data.newPassword,
      getEncryptedKey()
    );
    const body = {
      ResetPasswordToken: `${token}`,
      NewPassword: encryptedNewPassword,
    };
    setPasswordRequirementsColor(colors?.green);
    await dispatch(resetPasswordAction(body, navigateToRoute));
    localStorage.removeItem("ResetPasswordToken");
    if (
      resetSuccess === "Request failed with status code 500" ||
      resetSuccess === "Request failed with status code 400"
    ) {
      toast.error(ErrorConstant.RESET_PAWWORD_FAILED, {
        position: "top-right",
        autoClose: 3000,
        style: {
          color: "black",
          height: "100px",
          fontSize: "16px",
        },
      });
    }
  };

  return (
    <Grid container style={styles.mainGridContainer}>
      <ToastContainer />
      <LoadingOverlay isVisible={loginLoader} />
      <Grid item xs={12} sm={6}>
        <Paper elevation={3} style={styles.paperContainer}>
          <form
            style={styles?.formContainerStyle}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Typography sx={styles.pageTitlelabel}>
              {TitleConstants.CREATE_NEW_PASSWORD}
            </Typography>
            <Controller
              name="newPassword"
              control={control}
              defaultValue=""
              rules={{
                required: "NEW_PASSWORD_REQUIRED",
                minLength: {
                  value: NumericConstants.PASSWORD_MIN_LENGTH,
                  message: "MIN_PASSWORD_REQUIREMENT",
                },
                maxLength: {
                  value: NumericConstants.PASSWORD_MAX_LENGTH,
                  message: "MAX_PASSWORD_REQUIREMENT",
                },
                validate: (value) => {
                  if (!validateAtleastUpperLowerCase(value)) {
                    return "UPPER_LOWER_CASE_VALIDATION";
                  } else if (!validateAtleastNumericChar(value)) {
                    return "NUMERIC_VALIDATION";
                  } else if (!validateAtleastSpecialChar(value)) {
                    return "SPECIAL_CHAR_VALIDATION";
                  } else if (value.includes(" ")) {
                    return "SPACE_DETECTED";
                  }
                },
              }}
              render={({ field }) => (
                <TextField
                  placeholder="Enter New Password"
                  type="password"
                  sx={styles.passInputStyle}
                  {...field}
                />
              )}
            />
            {CheckAndSetError(errors.newPassword)}
            <Controller
              name="confirmNewPassword"
              control={control}
              defaultValue=""
              rules={{
                required: "CONFIRM_NEW_PASSWORD_REQUIRED",
                validate: (value) => {
                  if (!validateAtleastUpperLowerCase(value)) {
                    return "UPPER_LOWER_CASE_VALIDATION";
                  } else if (!validateAtleastNumericChar(value)) {
                    return "NUMERIC_VALIDATION";
                  } else if (!validateAtleastSpecialChar(value)) {
                    return "SPECIAL_CHAR_VALIDATION";
                  } else if (value.includes(" ")) {
                    return "SPACE_DETECTED";
                  } else if (value !== password) {
                    return "PASSWORDS_MISMATCH_ERROR";
                  }
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  placeholder="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          height: "auto",
                          minWidth: 35,
                          padding: 0,
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ alignSelf: "center" }}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={styles.passInputStyle}
                />
              )}
            />
            {CheckAndSetError(errors.confirmNewPassword)}
            {RenderPasswordRequirements(
              errors.newPassword,
              passwordRequirementsColor
            )}
            <Box sx={styles.buttonContainer}>
              <Button
                disabled={!isValid || !isDirty}
                sx={styles.buttonStyle}
                type="submit"
              >
                {TitleConstants.CONTINUE}
              </Button>
              <Link to="/">
                {" "}
                <Button sx={styles.buttonStyle}>{TitleConstants.CANCEL}</Button>
              </Link>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ResetPassword;
