import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  IdValueInterface,
  UserTenantDetailsInterface,
} from "../../../TenantData";
import RouteConstants from "../../../constants/RouteConstants";
import { setResetItemsInLocalStorage } from "../../../utils/authToken";
import { TokenConstants } from "../../../constants/CommonConstants";
import "./styles/TenantSelection.css";
import buttonStyles from "./styles/buttonStyles";

interface TenantCompanyListInterface {
  tenant: IdValueInterface;
  companies: Array<{ company: IdValueInterface }>;
}

const TenantSelection = () => {
  const navigateToRoute = useNavigate();
  const routeLocation = useLocation();
  const [tenantList, setTenantList] = useState<TenantCompanyListInterface[]>(
    []
  );

  const [companiesList, setCompaniesList] = useState<
    Array<{ company: IdValueInterface }>
  >([]);

  const [selectedTenant, setSelectedTenant] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<string>("");
  const [isShowTenantDropdown, setIsShowTenantDropdown] = useState(true);
  const [isShowCompanyDropdown, setIsShowCompanyDropdown] = useState(false);
  const [selectElementOptionLength, setSelectElementOptionLength] = useState(0);

  const calculateMaxLengthOfTenantOrCompany = (
    userTenantDetailsInterface: TenantCompanyListInterface[]
  ) => {
    let optionLength: number = selectElementOptionLength;
    if (userTenantDetailsInterface.length === 0) {
      optionLength = 0;
    } else {
      let lastIndex: number = userTenantDetailsInterface.length - 1;
      if (
        optionLength < userTenantDetailsInterface[lastIndex].tenant.value.length
      ) {
        optionLength =
          userTenantDetailsInterface[lastIndex].tenant.value.length;
      }
      for (
        let companyIndex: number = 0;
        companyIndex < userTenantDetailsInterface[lastIndex].companies.length;
        ++companyIndex
      ) {
        if (
          optionLength <
          userTenantDetailsInterface[lastIndex].companies[companyIndex].company
            .value.length
        ) {
          optionLength =
            userTenantDetailsInterface[lastIndex].companies[companyIndex]
              .company.value.length;
        }
      }
    }
    setSelectElementOptionLength(optionLength);
  };

  const selectOptionStyle = {
    width: `${selectElementOptionLength * 18}px`,
  };

  const prepareUniqueTenantList = (
    tenantCompaniesList: UserTenantDetailsInterface[]
  ): TenantCompanyListInterface[] => {
    let uniqueTenantsCompaniesList: TenantCompanyListInterface[] = [];
    for (let i: number = 0; i < tenantCompaniesList.length; ++i) {
      let index = uniqueTenantsCompaniesList.findIndex(
        (forEachTenant: TenantCompanyListInterface) =>
          forEachTenant.tenant.id === tenantCompaniesList[i].TenantId
      );
      if (index === -1) {
        let tenantCompanyDetails: TenantCompanyListInterface = {
          tenant: {
            id: tenantCompaniesList[i].TenantId,
            value: tenantCompaniesList[i].TenantName,
          },
          companies: [
            {
              company: {
                id: tenantCompaniesList[i].CompanyId,
                value: tenantCompaniesList[i].CompanyName,
              },
            },
          ],
        };
        uniqueTenantsCompaniesList.push(tenantCompanyDetails);
      } else {
        uniqueTenantsCompaniesList[index].companies.push({
          company: {
            id: tenantCompaniesList[i].CompanyId,
            value: tenantCompaniesList[i].CompanyName,
          },
        });
      }
    }
    calculateMaxLengthOfTenantOrCompany(uniqueTenantsCompaniesList);
    if (uniqueTenantsCompaniesList.length === 1) {
      setIsShowTenantDropdown(false);
    } else {
      setIsShowTenantDropdown(true);
    }
    setSelectedTenant(uniqueTenantsCompaniesList[0].tenant.id as string);
    setIsShowCompanyDropdown(true);
    setCompaniesList(uniqueTenantsCompaniesList[0].companies);
    setSelectedCompany(
      uniqueTenantsCompaniesList[0].companies[0].company.id as string
    );

    setTenantList(uniqueTenantsCompaniesList);
    return uniqueTenantsCompaniesList;
  };

  useEffect(() => {
    if (
      routeLocation.state &&
      Array.isArray(routeLocation.state.userTenantDetails)
    ) {
      const newUserTenantDetails: UserTenantDetailsInterface[] =
        routeLocation.state.userTenantDetails;
      prepareUniqueTenantList(newUserTenantDetails);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTenantOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedValue = event.target.value;
    setSelectedTenant(event.target.value);
    let index: number = tenantList.findIndex(
      (tenantObj: TenantCompanyListInterface) =>
        tenantObj.tenant.id === selectedValue
    );
    if (index !== -1) {
      setIsShowCompanyDropdown(true);
      setCompaniesList(tenantList[index].companies);
      setSelectedCompany(tenantList[index].companies[0].company.id);
    } else {
      setIsShowCompanyDropdown(false);
    }
  };
  const handleCompanyOnChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSelectedCompany(event.target.value);
  };

  const submitTenantSelectionForm = (e: any) => {
    e.preventDefault();
    setResetItemsInLocalStorage(
      TokenConstants.TENANT_ID_KEY,
      JSON.stringify(selectedTenant)
    );
    setResetItemsInLocalStorage(
      TokenConstants.COMPANY_ID_KEY,
      JSON.stringify(selectedCompany)
    );
    navigateToRoute(RouteConstants.URL_HOME_PAGE);
  };
  return (
    <div>
      <form onSubmit={submitTenantSelectionForm}>
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={400}
          alignItems={"center"}
          justifyContent={"center"}
          margin={"auto"}
          marginTop={5}
          padding={3}
          borderRadius={5}
          boxShadow={"5px 5px 10px #ccc"}
          sx={{ ":hover": { boxShadow: "10px 10px 20px #ccc" } }}
        >
          <label htmlFor="tenantSelection" className="form-controls">
            Select Tenant
          </label>
          <select
            id="tenantSelection"
            className="form-select form-controls"
            style={selectOptionStyle}
            disabled={!isShowTenantDropdown}
            value={selectedTenant}
            onChange={handleTenantOnChange}
          >
            {tenantList.map((tenantObj: TenantCompanyListInterface) => (
              <option key={tenantObj.tenant.id} value={tenantObj.tenant.id}>
                {tenantObj.tenant.value}
              </option>
            ))}
          </select>
          <label htmlFor="companySelection" className="form-controls">
            Select Company
          </label>
          <select
            id="companySelection"
            className="form-select form-controls"
            style={selectOptionStyle}
            value={selectedCompany}
            disabled={!isShowCompanyDropdown}
            onChange={handleCompanyOnChange}
          >
            {companiesList.map(
              (companiesObj: { company: IdValueInterface }) => (
                <option
                  key={companiesObj.company.id}
                  value={companiesObj.company.id}
                >
                  {companiesObj.company.value}
                </option>
              )
            )}
          </select>
          <Button sx={buttonStyles.root} type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

export default TenantSelection;
