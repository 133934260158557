import {
  dosageFormListService,
  saveProductEvaluationService,
} from "../services/createNewRunServices";
import {
  GET_DOSAGE_FORM_DATA_SUCCESS,
  GET_DOSAGE_FORM_DATA_FAILURE,
  SAVE_PRODUCT_EVALUATION_SUCCESS,
  SAVE_PRODUCT_EVALUATION_FAILURE,
  GET_DOSAGE_FORM_DATA_REQUEST,
  CREATE_RUN_PAGE_LOADER,
  SAVE_PRODUCT_EVALUATION_REQUEST,
  RESET_PRODUCT_EVALUATION_REQUEST,
} from "../types";
import store from "../store";
import { ErrorConstant } from "../../constants/CommonConstants";
import { saveRouteDataAction } from "./routeActions";
import { productEvaluationPageData, resetProductEvaluationDetailRequest } from "./dashboardActions";
import { dataResolve } from "../dataResolver";
import { AppDispatch } from "../services/rootDispatcher";

export const getDosageFormData = () => {
  let data = {
    ReferenceDataList: [
      {
        ReferenceDataName: "DosageForms",
        AdditionalData: {},
      },
    ],
    pageSize: 3,
  };
  store.dispatch(fetchDosageFormListAction(data));
};

export const fetchDosageFormListAction = (payload: any) => {
  return async (dispatch: AppDispatch) => {
    const selectedDosageForms =
      store.getState().createNewRunReducer?.requestData?.SelectedDosageForms ||
      [];
    const checkData = store.getState().createNewRunReducer?.dosageFormList;
    if (checkData === null) {
      startLoader();
    }
    dispatch({ type: GET_DOSAGE_FORM_DATA_REQUEST });
    try {
      const data = await dosageFormListService(payload);
      const newList: any[] = dataResolve(
        data?.PayLoad?.ReferenceDataList,
        selectedDosageForms
      );
      dispatch(fetchDosageFormListSuccess({ ReferenceDataList: newList }));
    } catch (error: any) {
      dispatch(
        fetchDosageFormListFailure(
          error?.StatusMessage || ErrorConstant?.DEFAULT_ERROR_MESSAGE
        )
      );
    } finally {
      stopLoader();
    }
  };
};

export const saveProductEvaluationAction = (
  payload: any,
  pageName?: any
): Promise<boolean> => {
  return new Promise<boolean>(async (resolve, reject) => {
    startLoader();
    store.dispatch(saveProductEvaluationRequest(payload));
    const historyData = store.getState()?.historyReducer;
    const dataToSave = {
      savedData: {
        ...historyData?.savedData,
        [pageName]: payload,
      },
    };
    await saveRouteDataAction(dataToSave);
    try {
      const data = await saveProductEvaluationService(payload);
      store.dispatch(saveProductEvaluationSuccess(data));
      if (data) {
        delete dataToSave?.savedData?.[pageName];
        await saveRouteDataAction(dataToSave).then(() => {
          resetProductEvaluationRequest();
          resetProductEvaluationDetailRequest();
          productEvaluationPageData(null);
        });
      }
      stopLoader();
      resolve(true);
    } catch (error: any) {
      store.dispatch(
        saveProductEvaluationFailure(
          error?.StatusMessage || ErrorConstant?.DEFAULT_ERROR_MESSAGE
        )
      );
      reject(false);
      // setAuthToken(undefined);
      stopLoader();
    }
  });
};

export const fetchDosageFormListSuccess = (data: any) => ({
  type: GET_DOSAGE_FORM_DATA_SUCCESS,
  payload: data,
});

export const fetchDosageFormListFailure = (error: string) => ({
  type: GET_DOSAGE_FORM_DATA_FAILURE,
  payload: error,
});

export const saveProductEvaluationSuccess = (data: any) => ({
  type: SAVE_PRODUCT_EVALUATION_SUCCESS,
  payload: data,
});

export const saveProductEvaluationFailure = (error: string) => ({
  type: SAVE_PRODUCT_EVALUATION_FAILURE,
  payload: error,
});

export const startLoader = () =>
  store.dispatch({ type: CREATE_RUN_PAGE_LOADER, payload: true });

export const stopLoader = () =>
  store.dispatch({ type: CREATE_RUN_PAGE_LOADER, payload: false });

export const saveProductEvaluationRequest = (data: any) => ({
  type: SAVE_PRODUCT_EVALUATION_REQUEST,
  payload: data,
});

export const resetProductEvaluationRequest = () =>
  store.dispatch({ type: RESET_PRODUCT_EVALUATION_REQUEST });
