import React from "react";
import { colors } from "../../../config/theme";

export const OutputPageStyles = {
  mainSection: {
    height: "100%",
    overflowY: "auto",
    padding: "0px",
    marginTop: "8px",
  } as React.CSSProperties,
  mainBox: { mt: 0 },
  userInputInfoBlock: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: "90%",
  } as React.CSSProperties,
  titleStyle: {
    color: colors?.purple,
    mt: 8,
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: {
      xs: "16px",
      sm: "17px",
      md: "19px",
      lg: "21px",
      xl: "24px",
    },
    lineHeight: {
      xs: "21.13px",
      sm: "23.13px",
      md: "25.13px",
      lg: "27.13px",
      xl: "28.13px",
    },
  },
  buttonContainer: {
    display: "grid",
    justifyContent: "center",
    alignItems: "center",
    gridTemplateColumns: "auto auto",
    marginBottom: "32px",
  },
  buttonStyle: {
    color: colors?.fullWhite,
    textTransform: "capitalize",
    backgroundColor: colors?.purple,
    borderRadius: "10px",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: {
      xs: "14px",
      sm: "15px",
      md: "17px",
      lg: "19px",
      xl: "20px",
    },
    lineHeight: {
      xs: "21px",
      sm: "21px",
      md: "24px",
      lg: "26px",
      xl: "28px",
    },
    padding: "10px 25px",
    border: "1px solid #000000",
    "&:hover": {
      backgroundColor: colors?.purpleLight,
    },
    "&.Mui-disabled": {
      background: colors?.grey,
      color: colors?.blackLight,
    },
  },
  editButtonStyle: {
    margin: "auto",
  },
  closeButtonStyle: {
    ml: 2,
  },
};
