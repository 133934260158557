import { FC, useEffect, useState } from "react";
import ToastComponent from "../ToastComponents";
import { shallowEqual, useSelector } from "react-redux";
import { TitleConstants, ToastStatus } from "../../constants/CommonConstants";
import { getErrorAction } from "../../stateManagement/actions/securityActions";
import { RootState } from "../../stateManagement/services/rootDispatcher";

const ToastSection:FC = () => {
  const [isProductevaluationSuccess] = useSelector((state: RootState) => {
    return [state.createNewRunReducer?.isProductevaluationSuccess];
  }, shallowEqual);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (isProductevaluationSuccess) {
      setVisible(true);
    }
    return () => setVisible(false);
  }, [isProductevaluationSuccess]);

  const handleToastClose = () => {
    setVisible(false);
    getErrorAction({ isNofityShow: false })
  };

  return (
    <ToastComponent
      isVisible={isVisible}
      showToastFor={isProductevaluationSuccess ? ToastStatus.SUCCESS : ToastStatus.FAIL}
      showToastContent={
        TitleConstants.PRODUCT_EVALUATION_RUN_SUBMIITED_SUCCESSFULLY
      }
      onClose={handleToastClose}
    />
  );
};

export default ToastSection;
