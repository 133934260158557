import { useNavigate } from "react-router-dom";
import RouteConstants from "../constants/RouteConstants";
import { resetAll } from "../stateManagement/actions/resetActions";

const useLogout = () => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      resetAll().then(() => {
        navigate(RouteConstants.URL_LOGIN, { replace: true });
      });
    } catch (error) {
      console.warn("Error during logout[]:", error);
    }
  };

  return { logout };
};

export default useLogout;
