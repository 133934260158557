export const styles = {
  mainContain: {
    height: "100%",
    overflowY: 'auto',
    maxWidth: "100%",
    margin: "0 auto",
    flexDirection: "column",
    alignItems: "center",
  } as React.CSSProperties,
  mainTableContainer: {
    width: "95%",
    margin: "0 auto",
    border: "none",
    // height: "400px",
    // overflowY: "auto",
  },
  labelButtonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "10px",
    marginTop: "60px",
  },
  labelText: { marginRight: 1, fontWeight: "500" },
};
