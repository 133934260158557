import { colors } from "../../config/theme";

export const CommonTableStyles = {
  mainSection: {
    display: "flex",
    flexDirection: "column",
    margin: "0 auto",
    maxWidth: "90%",
    marginBottom: "32px",
  } as React.CSSProperties,
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  } as React.CSSProperties,
  tableTitleStyle: {
    color: colors?.purple,
    fontFamily: "Roboto",
    fontWeight: "500",
    fontSize: {
      xs: "16px",
      sm: "17px",
      md: "19px",
      lg: "21px",
      xl: "24px",
    },
    lineHeight: {
      xs: "21.13px",
      sm: "23.13px",
      md: "25.13px",
      lg: "27.13px",
      xl: "28.13px",
    },
  },
  tableMainBox: { border: 0.5, borderRadius: 1, mt: 1 },
  tableContainer: {
    overflowY: "auto",
    minHeight: "180px",
    maxHeight: "665px",
    borderRadius: 0,
    borderBottom: 0,
    border: 0,
    boxShadow: "none",
  },
  noRecordFound: { minHeight: "100px" },
  tableStyle: {
    minWidth: 650,
    borderRadius: 0,
    borderBottom: 0,
    border: 0,
    overflowY: "auto",
    // tableLayout: "fixed",
  },
  tableHeadStyle: { background: colors?.themeBlue },
  tableHeadCell: {
    textAlign: "left",
    color: colors.fullWhite,
    borderColor: colors?.themeBg,
    backgroundColor: colors?.themeBg,
    "&:hover": {
      backgroundColor: colors?.themeBg,
      color: colors.fullWhite,
    },
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 0.5,
    minHeight: 20,
  },
  sortedCellStyle: {
    textAlign: "left",
    color: colors.fullWhite,
    borderColor: colors?.themeBg,
    backgroundColor: colors?.themeBg,
    margin: 0,
    padding: 0,
    paddingTop: 0, // Add missing properties
    paddingBottom: 0, // Add missing properties
    paddingLeft: 0, // Add missing properties
    minHeight: 0,
    "&:hover": {
      backgroundColor: colors?.themeBg,
      color: colors.fullWhite,
      border: 0,
      borderBottomWidth: 1,
      borderColor: "white",
    },
  },
  tableBodyCell: {
    textAlign: "left",
    paddingTop: 1.5,
    paddingBottom: 1.5,
    paddingLeft: 0.5,
  },
  tableCellNDC: {
    minWidth: 70,
    paddingLeft: 0.5,
    paddingRight: 0.5,
  },
  tableCellIngredientName: {
    paddingLeft: 2,
    minWidth: 200,
  },
  tableCellIngredientDataSourceName: {
    minWidth: 150,
    maxWidth: 200,
  },
  tableCellDosageForm: {
    minWidth: 150,
    maxWidth: 150,
  },
  tableCellCount: {
    textAlign: "center",
    maxWidth: 60,
  },
  exportButtonStyles: { color: colors?.purple },
  CSVLinkStyle: { textDecoration: "none", color: "inherit" },
  emptyDataCell: { p: 3 },
  testStyle: { border: 1 },
};

export const tableHeaderStyles = (item: string, isSorted: boolean) => {
  const baseStyles = [CommonTableStyles.tableHeadCell];

  switch (item) {
    case "IngredientName":
      return [...baseStyles, CommonTableStyles.tableCellIngredientName];
    case "IngredientDataSourceName":
      return [
        ...baseStyles,
        CommonTableStyles.tableCellIngredientDataSourceName,
      ];
    case "DosageForm":
      return [...baseStyles, CommonTableStyles.tableCellDosageForm];
    case "DeadCount":
    case "LiveCount":
    case "NoRecordBl":
      return [...baseStyles, CommonTableStyles.tableCellCount];
    default:
      return baseStyles;
  }
};

export const tableBodyStyles = (item: string) => {
  switch (item) {
    case "IngredientName":
      return [
        CommonTableStyles.tableBodyCell,
        CommonTableStyles.tableCellIngredientName,
      ];
    case "IngredientDataSourceName":
      return [
        CommonTableStyles.tableBodyCell,
        CommonTableStyles.tableCellIngredientDataSourceName,
      ];
    case "DosageForm":
      return [
        CommonTableStyles.tableBodyCell,
        CommonTableStyles.tableCellDosageForm,
      ];
    case "DeadCount":
    case "LiveCount":
    case "NoRecordBl":
      return [
        CommonTableStyles.tableBodyCell,
        CommonTableStyles.tableCellCount,
      ];
    default:
      return [{}, CommonTableStyles.tableBodyCell];
  }
};

export const tableCellStyles = (item: string) => {
  switch (item) {
    case "NDC":
      return [CommonTableStyles.tableCellNDC];
    default:
      return [{}];
  }
};

export const cellDataStyle = (item: string, value: string) => {
  if (value) {
    switch (item) {
      case "DeadCount":
      case "LiveCount":
      case "NoRecordBl":
        if (
          typeof value === "boolean" ||
          (typeof value === "string" && value?.length < 4)
        ) {
          return "center";
        } else {
          return "left";
        }
      default:
        return "left";
    }
  }
};
