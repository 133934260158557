// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.info {
  margin-bottom: 8px;
}

.label {
  font-weight: bold;
  color: #24448a
}

.value {
  color: #666;
  display: flex;
}

`, "",{"version":3,"sources":["webpack://./src/components/SettingsComponent/settingsPage.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB;AACF;;AAEA;EACE,WAAW;EACX,aAAa;AACf","sourcesContent":["\n.info {\n  margin-bottom: 8px;\n}\n\n.label {\n  font-weight: bold;\n  color: #24448a\n}\n\n.value {\n  color: #666;\n  display: flex;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
