import { ApiClient } from "./apiServices/apiClient";
import {
  ProductEvaluationEditDetailRoute,
  ProductEvaluationEditResultRoute,
  ProductEvaluationListRoute,
  ProductEvaluationOutputRoute,
} from "./apiServices/routeObjects";

export const productEvaluationListService = async (payload: any) => {
  try {
    let obj = {
      ...ProductEvaluationListRoute,
      data: { ...payload },
    };
    const response = await ApiClient(obj);
    return response.data;
  } catch (error: any) {
    const errorStatus = error.response.data;
    throw new Error(errorStatus.StatusMessage);
  }
};

export const productEvaluationOutputService = async (payload: any) => {
  try {
    // const params = "?Id={ExecutionRunId}";
    const params = `?${payload}`;
    const response = await ApiClient({
      ...ProductEvaluationOutputRoute(params),
    });
    return response.data;
  } catch (error: any) {
    const errorData: any = new Error(error?.message);
    errorData.code = error?.status;
    errorData.info =
      error?.response?.data?.StatusMessage ||
      error?.statusText ||
      error?.message;
    throw errorData;
  }
};

export const productEvaluationDetailService = async (data: any) => {
  try {
    const params = `?ExecutionDefinitionId=${data}`;
    const response = await ApiClient({
      ...ProductEvaluationEditDetailRoute(params),
    });
    return response.data;
  } catch (error: any) {
    throw error.data || error;
  }
};

export const productEvaluationResultService = async (data: any) => {
  try {
    const params = `?ExecutionRunId=${data}`;
    const response = await ApiClient({
      ...ProductEvaluationEditResultRoute(params),
    });
    return response!.data;
  } catch (error: any) {
    throw error.data || error;
  }
};
