import { FC, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastStatus } from "../constants/CommonConstants";

interface Props {
  isVisible?: boolean;
  showToastFor?: string;
  showToastContent?: string;
  customStyles?: object;
  timeout?: number;
  onClose?: () => void;
  className?: string;
  toastStyle?: object;
}

const defaultStyle = {
  color: "black",
  fontSize: "12px",
};
const ToastComponent: FC<Props> = ({
  isVisible = false,
  showToastFor,
  showToastContent,
  customStyles = {},
  timeout = 3000,
  onClose = () => {},
  toastStyle = {},
  className,
}) => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const toastId = useRef<string | number | null>(null);

  useEffect(() => {
    if (isVisible) {
      showToastMessage();
    }
    // Clear the timer when the component unmounts or when the dependencies change
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
    // eslint-disable-next-line
  }, [isVisible, showToastFor, showToastContent, customStyles, timeout]);

  const showToastMessage = () => {
    // const position = toast.POSITION.TOP_RIGHT;
    const options = {
      // position,
      draggablePercent: 60,
      className: `${className}`, // Combine styles from CSS module and prop
      style: { ...defaultStyle, ...customStyles },
      toastStyle: toastStyle,
      toastId: toastId.current || undefined, // Adjust toastId to be undefined if null
      onClose, // Add onClose to options
    };
    if (toastId.current) {
      toast.update(toastId.current, {
        ...options,
        render: showToastContent,
      });
    } else {
      switch (showToastFor) {
        case ToastStatus.SUCCESS:
          toastId.current = toast.success(
            showToastContent || "Success !",
            options
          );
          break;
        case  ToastStatus.FAIL:
          toastId.current = toast.error(
            showToastContent || "Failure !",
            options
          );
          break;
        default:
          break;
      }
    }

    // Set a new timer to dismiss the toast
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (toastId.current !== null) {
        toast.dismiss(toastId.current);
      }
      timerRef.current = null; // Reset timer reference
    }, timeout);
  };

  return (
    <ToastContainer
    // bodyClassName={() => "text-sm font-white font-med block p-3"}
    />
  );
};

export default ToastComponent;
