export const API = {
  login: "security/SignInAsync/",
  forgetPassword: "security/ForgotPassword/",
  resetPassword: "security/ResetUserPassword/",
  resetPasswordValidator: "security/ResetPasswordValidator/",
  tenantDetails: "security/getTenantDetails/",
  tenantCompanyDetails: "security/getUserDetails/",
  userDetails: "security/getUserDetails/",
  dosageFormList: "referencedata/",
  saveproductevaluation: "dagny/saveproductevaluation/", 
  productEvaluationList: "dagny/productevaluationlist/",
  productEvaluationOutput: "dagny/getByExectionRunId",
  getproductevaluation: "dagny/getproductevaluation",
  getProductEvaluationResults: "dagny/getproductevaluationresults",
};
