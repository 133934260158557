// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` .logoBottomRight {
     height: 50px;
     width: auto;
     bottom: 16px;
     right: 16px;
     position: fixed;
 }`, "",{"version":3,"sources":["webpack://./src/pages/auth/Login/styles.css"],"names":[],"mappings":"CAAC;KACI,YAAY;KACZ,WAAW;KACX,YAAY;KACZ,WAAW;KACX,eAAe;CACnB","sourcesContent":[" .logoBottomRight {\n     height: 50px;\n     width: auto;\n     bottom: 16px;\n     right: 16px;\n     position: fixed;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
