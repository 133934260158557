import { colors } from "../../../../config/theme";

const buttonStyles = {
  root: {
    marginTop: 3,
    color: colors?.fullWhite,
    fontWeight: "700",
    fontSize: {
      xs: "12px",
      sm: "14px",
      md: "16px",
      lg: "18px",
      xl: "20px",
    },
    lineHeight: {
      xs: "17px",
      sm: "19px",
      md: "21px",
      lg: "23px",
      xl: "28px",
    },
    textTransform: "capitalize",
    backgroundColor: colors?.purple,
    borderRadius: "10px",
    padding: {
      xs: "2px 17px",
      sm: "4px 19px",
      md: "6px 21px",
      lg: "8px 23px",
      xl: "10px 25px",
    },
    fontFamily: "Roboto",
    border: "1px solid #176893",
    "&:hover": {
      backgroundColor: colors?.purpleLight,
    },
    "&.Mui-disabled": {
      background: colors?.grey,
      color: colors?.blackLight,
    },
  },
};

export default buttonStyles;
