import { Dispatch } from "redux";
import { HttpStatusCodes, StorageKeys } from "../../constants/CommonConstants";
import {
  forgotPasswordService,
  getTenantDetailsService,
  getUserDetailService,
  loginService,
  resetPasswordService,
  resetPasswordValidatorService,
} from "../services/securityServices";
import store from "../store";
import {
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  GET_TENANT_DETAILS_FAILURE,
  GET_TENANT_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAILURE,
  GET_USER_DETAILS_REQUEST,
  GET_USER_DETAILS_SUCCESS,
  INCREMENT_LOGIN_ATTEMPTS,
  LOGIN_FAILURE,
  LOGIN_LOADER,
  LOGIN_SUCCESS,
  LOGOUT,
  RESET_AUTH_TOKEN,
  RESET_LOGIN_ATTEMPTS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_SECURITY_REQUEST,
  RESET_VALIDATOR_FAILURE,
  RESET_VALIDATOR_SUCCESS,
  SET_ERROR_LOGS,
  TENANT_DETAILS_REQUEST,
} from "../types";
import { setAuthToken } from "../../utils/authToken";
import { saveProductEvaluationRequest } from "./createNewRunActions";
import RouteConstants from "../../constants/RouteConstants";
import { colors } from "../../config/theme";
import { AppDispatch } from "../services/rootDispatcher";
import { productEvaluationOutputSuccess } from "./dashboardActions";

export const loginUser = (encryptedUserCredentials: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      const data = await loginService(encryptedUserCredentials);
      dispatch(loginSuccess(data));
      dispatch(getTenantDetailsAction());
    } catch (error: any) {
      dispatch(loginFailure(error?.data?.StatusMessage || error?.message));
      setAuthToken(undefined);
    }
  };
};

export const forgotPasswordAction = (userCredentials: any, navigate?: any) => {
  startLoader();
  return async (dispatch: Dispatch) => {
    try {
      const apiResponse = await forgotPasswordService(userCredentials);
      if (apiResponse.StatusCode === HttpStatusCodes.SUCCESS_OK) {
        dispatch(forgotPasswordSuccess(apiResponse?.StatusMessage));
        const forgotToLogin = true;
        localStorage.setItem(
          StorageKeys.FORGET_TO_LOGIN,
          JSON.stringify(forgotToLogin)
        );
        const ResetPasswordToken = apiResponse?.PayLoad?.ResetPasswordToken;
        localStorage.setItem(
          StorageKeys.RESET_PASSWORD_TOKEN,
          JSON.stringify(ResetPasswordToken)
        );
        setTimeout(() => {
          // window.location.href = "/";
          navigate("/", { replace: true });
          stopLoader();
        }, 2000);
      }
    } catch (error: any) {
      dispatch(
        forgotPasswordFailure(
          error?.StatusMessage || error?.statusText || error?.message
        )
      );
      stopLoader();
      // if (Number(error.StatusCode) === HttpStatusCodes.CLIENT_ERROR_NOT_FOUND) {
      //   dispatch(
      //     forgotPasswordSuccess(error?.StatusMessage || error?.statusText)
      //   );
      //   const forgotToLogin = true;
      //   localStorage.setItem("forgotToLogin", JSON.stringify(forgotToLogin));
      //   // window.location.href = "/";
      //   navigate("/", { replace: true });
      // }
    }
  };
};

export const resetPasswordAction = (bodyParam: any, navigate?: any) => {
  return async (dispatch: Dispatch) => {
    resetPassWordRequest();
    try {
      const data = await resetPasswordService(bodyParam);
      dispatch(resetPasswordSuccess(data));
      const resetToLogin = true;
      localStorage.setItem(
        StorageKeys.RESET_TO_LOGIN,
        JSON.stringify(resetToLogin)
      );
      setTimeout(() => {
        // navigate("/", { replace: true });
        window.location.href = "/";
        stopLoader();
      }, 1000);
    } catch (error: any) {
      dispatch(
        resetPasswordFailure(
          error?.StatusMessage || error?.statusText || error?.message
        )
      );
      setTimeout(() => {
        // navigate("/", { replace: true });
        window.location.href = "/";
        stopLoader();
      }, 1000);
    }
  };
};

export const resetValidatorAction = (body: any, navigate?: any) => {
  return async (dispatch: AppDispatch) => {
    try {
      const data = await resetPasswordValidatorService(body);
      dispatch(resetValidatorSuccess(data));
    } catch (error: any) {
      dispatch(resetValidorFailure(error || error?.message));
      const resetValidatorFails = true;
      localStorage.setItem(
        StorageKeys.RESET_VALIDATOR_TO_LOGIN,
        JSON.stringify(resetValidatorFails)
      );
      setTimeout(() => {
        // navigate("/", { replace: true });
        window.location.href = "/";
      }, 1000);
    }
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};

export const getTenantDetailsAction = () => {
  return async (dispatch: AppDispatch) => {
    tenantDetailRequest();
    try {
      const data = await getTenantDetailsService();
      dispatch(getTenantDetailsSuccess(data));
      dispatch(getUserDetailsAction());
    } catch (error: any) {
      dispatch(
        getTenantDetailsFailure(
          error?.statusText ||
            error?.message ||
            "Some error occured in retrieving Tenant Details"
        )
      );
      setAuthToken(undefined);
    }
  };
};
export const getUserDetail = () => {
  store.dispatch(getUserDetailsAction());
};

export const getUserDetailsAction = () => {
  const tenantData =
    store.getState().securityReducer?.tenantDetailSuccessMsg?.PayLoad;
  return async (dispatch: AppDispatch) => {
    // const checkData = store.getState().securityReducer?.userDetailsSuccessMsg;
    // if (!checkData) {
    startLoader();
    // }
    dispatch({ type: GET_USER_DETAILS_REQUEST });
    try {
      const data = await getUserDetailService(tenantData);
      dispatch(getUserDetailsSuccess(data));
      dispatch(getHistoryAction());
    } catch (error: any) {
      dispatch(
        getUserDetailsFailure(
          error?.statusText ||
            error?.message ||
            "Some error occured in retrieving User Details"
        )
      );
      setAuthToken(undefined);
    } finally {
      stopLoader();
    }
  };
};

export const getHistoryAction = () => {
  const savedData = store.getState().historyReducer?.savedData;
  return async (dispatch: AppDispatch) => {
    try {
      if (savedData) {
        if (savedData[RouteConstants?.URL_CREATE_NEW_RUN]) {
          dispatch(
            saveProductEvaluationRequest(
              savedData[RouteConstants?.URL_CREATE_NEW_RUN]
            )
          );
        } else if (savedData[RouteConstants?.URL_PRODUCT_EVALUATION_OUTPUT]) {
          dispatch(
            productEvaluationOutputSuccess(
              savedData[RouteConstants?.URL_PRODUCT_EVALUATION_OUTPUT]
            )
          );
        }
      }
    } catch (error: any) {
      console.warn("error in get histor[]:", error);
    }
  };
};

export const getAuthAction = (data: any) => {
  try {
    store.dispatch(getAuth(data));
  } catch (error) {
    console.warn("error in save authData[]:", error);
  }
};

export const getErrorAction = (data: any) => {
  try {
    store.dispatch(getError(data));
  } catch (error) {
    console.warn("error in save API error[]:", error);
  }
};

export const loginSuccess = (data: any) => ({
  type: LOGIN_SUCCESS,
  payload: data,
});

export const loginFailure = (error: string) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const incrementLoginAttempts = () => ({
  type: INCREMENT_LOGIN_ATTEMPTS,
});

export const resetLoginAttempts = () => ({
  type: RESET_LOGIN_ATTEMPTS,
});

export const forgotPasswordSuccess = (data: any) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: data,
});

export const forgotPasswordFailure = (error: string) => ({
  type: FORGOT_PASSWORD_FAILURE,
  payload: error,
});
export const resetPasswordSuccess = (data: any) => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const resetPasswordFailure = (error: any) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: error,
});

export const resetValidatorSuccess = (data: any) => ({
  type: RESET_VALIDATOR_SUCCESS,
  payload: data,
});

export const resetValidorFailure = (error: any) => ({
  type: RESET_VALIDATOR_FAILURE,
  payload: error,
});

export const getTenantDetailsSuccess = (data: any) => ({
  type: GET_TENANT_DETAILS_SUCCESS,
  payload: data,
});

export const getTenantDetailsFailure = (error: any) => ({
  type: GET_TENANT_DETAILS_FAILURE,
  payload: error,
});

export const getUserDetailsSuccess = (data: any) => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: data,
});

export const getUserDetailsFailure = (error: any) => ({
  type: GET_USER_DETAILS_FAILURE,
  payload: error,
});

export const getAuth = (data: string) => ({
  type: RESET_AUTH_TOKEN,
  payload: data,
});

export const getError = (data: any) => ({
  type: SET_ERROR_LOGS,
  payload: data,
});

export const startLoader = () =>
  store.dispatch({ type: LOGIN_LOADER, payload: true });

export const stopLoader = () =>
  store.dispatch({ type: LOGIN_LOADER, payload: false });

export const tenantDetailRequest = () =>
  store.dispatch({ type: TENANT_DETAILS_REQUEST });

export const resetRequest = () =>
  store.dispatch({ type: RESET_SECURITY_REQUEST });

export const resetPassWordRequest = () =>
  store.dispatch({ type: RESET_PASSWORD_REQUEST });

export let rulesListColor = {
  minLength: "",
  maxLength: "",
  upperLowerCase: "",
  numeric: "",
  special: "",
};

export const updateRulesListColor = (color: string = colors?.fullBlack) => {
  rulesListColor.minLength = color;
  rulesListColor.maxLength = color;
  rulesListColor.upperLowerCase = color;
  rulesListColor.numeric = color;
  rulesListColor.special = color;
  return rulesListColor;
};
