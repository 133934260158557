import { Avatar, Divider, Paper } from "@mui/material";
import { SettingsConstants } from "../../constants/CommonConstants";
import InfoItem from "./InfoItem";
import "./settingsPage.css";
import { settingComponentStyles as styles } from "./styles";

interface UserDataProps {
  TenantId: string;
  ActiveBl: boolean;
  RetiredBy: string | null;
  RetiredOn: string;
  CreatedBy: string;
  CreatedOn: string;
  UpdatedBy: string;
  UpdatedOn: string;
  CompanyId: string;
  UserId: string;
  FirstName: string;
  LastName: string;
  MiddleName: string;
  EmailAddress: string;
  AlternateEmailAddress: string;
  OfficePhoneNumber: string;
  HomePhoneNumber: string;
  CellPhoneNumber: string;
  OrganizationName: string;
}

interface UserDetailsProps {
  UserData: UserDataProps;
}

const UserDetails: React.FC<UserDetailsProps> = ({ UserData }) => {
  return (
    <Paper elevation={3} sx={styles.paperContainer}>
      <Avatar
        sx={styles.avatarStyles}
        src={SettingsConstants.USER_IMAGE}
        alt="User Avatar"
      />
      <Divider sx={styles.dividerStyles} />
      <InfoItem
        label={SettingsConstants.ORGANIZATION_NAME}
        value={UserData?.OrganizationName || ""}
      />
      <InfoItem
        label={SettingsConstants.FIRST_NAME}
        value={UserData?.FirstName || ""}
      />
      <InfoItem
        label={SettingsConstants.MIDDLE_NAME}
        value={UserData?.MiddleName || ""}
      />
      <InfoItem
        label={SettingsConstants.LAST_NAME}
        value={UserData?.LastName || ""}
      />
      <InfoItem
        label={SettingsConstants.EMAIL_ADDRESS}
        value={UserData?.EmailAddress || ""}
      />
      <InfoItem
        label={SettingsConstants.PHONE_NUMBER}
        value={UserData?.CellPhoneNumber || ""}
      />
      <InfoItem
        label={SettingsConstants.ALTERNATIVE_EMAIL_ADDRESS}
        value={UserData?.AlternateEmailAddress || ""}
      />
    </Paper>
  );
};

export default UserDetails;
