import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { TokenConstants } from "../constants/CommonConstants";
import RouteConstants from "../constants/RouteConstants";

interface Auth {
  token: string | null;
}

const PrivateRoutes: React.FC = () => {
  const [auth, setAuth] = useState<Auth>({ token: localStorage.getItem(TokenConstants.TOKEN_NAME) });

  useEffect(() => {
    const handleTokenChange = () => {
      const token = localStorage.getItem(TokenConstants.TOKEN_NAME);
      setAuth({ token });
    };
    // Add event listener for custom event
    window.addEventListener('tokenChanged', handleTokenChange);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('tokenChanged', handleTokenChange);
    };
  }, []);

  return auth.token ? <Outlet /> : <Navigate to={RouteConstants.URL_LOGIN} />;
};

export default PrivateRoutes;