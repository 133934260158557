import {
  GET_PRODUCT_EVALUATION_LIST_SUCCESS,
  GET_PRODUCT_EVALUATION_LIST_FAILURE,
  GET_PRODUCT_EVALUATION_OUTPUT_SUCCESS,
  GET_PRODUCT_EVALUATION_OUTPUT_FAILURE,
  GET_PRODUCT_EVALUATION_LIST_REQUEST,
  GET_PRODUCT_EVALUATION_DETAILS_SUCCESS,
  GET_PRODUCT_EVALUATION_DETAILS_FAILURE,
  LOADER_ACTION,
  RESET_PRODUCT_EVALUATION_DETAILS_REQUEST,
  PRODUCT_EVALUATION_PAGE_DATA,
} from "../types";

export const dashboardInitialState = {
  productevaluationlist: null,
  productevaluationOutput: null,
  isProductDetailSuccess: false,
  isProductDetailFail: false,
  productDetail: null,
  listLoading: false,
  pageData: {
    pageNumber: 0,
    pageSize: 10,
  },
};

const dashboardReducer = (state = dashboardInitialState, action: any) => {
  switch (action.type) {
    case PRODUCT_EVALUATION_PAGE_DATA:
      return {
        ...state,
        pageData: action?.payload,
      };
    case GET_PRODUCT_EVALUATION_LIST_REQUEST:
      return {
        ...state,
        listLoading: true,
      };
    case GET_PRODUCT_EVALUATION_LIST_SUCCESS:
      return {
        ...state,
        productevaluationlist: action.payload,
        listLoading: false,
        error: null,
      };
    case GET_PRODUCT_EVALUATION_LIST_FAILURE:
      return {
        ...state,
        productevaluationlist: null,
        listLoading: false,
        error: action.payload,
      };
    case GET_PRODUCT_EVALUATION_OUTPUT_SUCCESS:
      return {
        ...state,
        productevaluationOutput: action.payload,
        error: null,
      };
    case GET_PRODUCT_EVALUATION_OUTPUT_FAILURE:
      return {
        ...state,
        productevaluationOutput: null,
        error: action.payload,
      };
    case GET_PRODUCT_EVALUATION_DETAILS_SUCCESS:
      return {
        ...state,
        isProductDetailSuccess: true,
        isProductDetailFail: false,
        productDetail: action.payload?.PayLoad || action.payload?.payload,
      };
    case GET_PRODUCT_EVALUATION_DETAILS_FAILURE:
      return {
        ...state,
        isProductDetailSuccess: false,
        isProductDetailFail: true,
        productDetail: null,
        error: action.payload,
      };
    case RESET_PRODUCT_EVALUATION_DETAILS_REQUEST:
      return {
        ...state,
        isProductDetailSuccess: false,
        isProductDetailFail: false,
        productDetail: null,
        error: null,
      };
    case LOADER_ACTION:
      return {
        ...state,
        listLoading: action.payload,
      };
    default:
      return state;
  }
};

export default dashboardReducer;
