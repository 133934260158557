import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import Divider from "@mui/material/Divider";
import "./styles.css";
import { Box, ListItemIcon, Tooltip, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";

const drawerWidth = 64;

const NavigationDrawer: React.FC = () => {
  const navigateToRoute = useNavigate();
  const isLaptop = useMediaQuery("(max-width: 1024px)");
  const isTablet = useMediaQuery("(max-width: 768px)");
  const isMobile = useMediaQuery("(max-width: 480px)");
  const toggleDrawer = (routeName: string) => {
    navigateToRoute(routeName);
  };

  return (
    <Drawer
      variant="permanent"
      open={true}
      sx={{
        width: drawerWidth,
        marginTop: "20px",
        flexShrink: 0,
        alignItems: "center",
        mt: 8,
        mr: isMobile ? 4 : isTablet ? 4 : isLaptop ? 4 : 2, // Adjust margin based on screen size
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          backgroundColor: "#24448a",
          color: "white",
          display: "flex",
          mr: isMobile ? 4 : isTablet ? 4 : isLaptop ? 14 : 2, // Adjust margin based on screen size
          mt: 8,
          pb: 8,
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <Box>
        <List>
          <ListItem
            button
            key="Home"
            onClick={() => toggleDrawer(RouteConstants.URL_HOME_PAGE)}
          >
            <ListItemIcon>
              <Tooltip id="icon-home" title="Home">
                <HomeIcon sx={{ color: "white" }} />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
          <Divider sx={{ backgroundColor: "white" }} />
          {/* Add more top items here */}
        </List>
      </Box>
      <Box>
        <List>
          <Divider sx={{ backgroundColor: "white", mb: 1 }} />
          <ListItem
            button
            key="Settings"
            onClick={() => toggleDrawer(RouteConstants.URL_SETTINGS)}
          >
            <ListItemIcon>
              <Tooltip id="icon-settings" title="Settings">
                <SettingsIcon sx={{ color: "white" }} />
              </Tooltip>
            </ListItemIcon>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;
