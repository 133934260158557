import CryptoJS from "crypto-js";
import { Typography } from "@mui/material";
import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";
import moment from "moment";

export const showErrorMessage = (
  errorType: Boolean,
  errorCaption: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>>
) =>
  Boolean(errorType) ? (
    <Typography>{errorCaption.toString()}</Typography>
  ) : null;

export const validatePassword = (password: string): boolean => {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_\-[\]<>?~/\\|]).{8,16}$/.test(
    password
  );
};

export const validateFieldNotEmpty = (value: string): boolean => {
  return !/^\s*$/.test(value);
};

export const validateEmail = (email: string): boolean => {
  return /^[A-Za-z0-9!#$%&'*+\-=?^_`{|.}]+@[A-Za-z0-9]+\.[A-Za-z]+$/.test(
    email
  );
};

export const validateAtleastUpperLowerCase = (inputText: string): boolean => {
  return /(?=.*[a-z])(?=.*[A-Z])/.test(inputText);
};

export const validateAtleastNumericChar = (inputText: string): boolean => {
  return /\d/.test(inputText);
};

export const validateAtleastSpecialChar = (inputText: string): boolean => {
  return /[!@#$%^&*()_\-[\]<>?~/\\|]/.test(inputText);
};

export function getEncryptedKey(): string {
  return process.env.REACT_APP_CRYPTO_KEY || "";
}

export function encryptData(msgString: string, strKey: string): string {
  const key = CryptoJS.enc.Utf8.parse(strKey);
  var iv = CryptoJS.lib.WordArray.random(16);
  var encrypted = CryptoJS.AES.encrypt(msgString, key, {
    iv: iv,
  });
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

export function decryptData(ciphertextStr: string, strKey: string): string {
  const key: CryptoJS.lib.WordArray = CryptoJS.enc.Utf8.parse(strKey);
  const ciphertext: CryptoJS.lib.WordArray =
    CryptoJS.enc.Base64.parse(ciphertextStr);

  const iv: CryptoJS.lib.WordArray = ciphertext.clone();
  iv.sigBytes = 16;
  iv.clamp();
  ciphertext.words.splice(0, 4);
  ciphertext.sigBytes -= 16;

  let encryptedText: string = CryptoJS.enc.Base64.stringify(ciphertext);

  const decrypted: CryptoJS.lib.WordArray = CryptoJS.AES.decrypt(
    encryptedText,
    key,
    { iv: iv }
  );
  return decrypted.toString(CryptoJS.enc.Utf8);
}

export function formatTime(timeString: string) {
  return moment(timeString).format("MM/DD/YYYY hh:mm:ss A");
}
export function isUnicodeEscapeSequence(value: string) {
  return (
    typeof value === "string" && value.length === 1 && value.charCodeAt(0) <= 31
  );
}

export function convertUnicodeEscapeSequence(value: string) {
  return value.charCodeAt(0).toString();
}
