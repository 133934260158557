import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { resetValidatorAction } from "../../stateManagement/actions/securityActions";
import ResetPassword from "./ResetPassword";
import { TitleConstants } from "../../constants/CommonConstants";
import LoadingOverlay from "../Loader/LoadingOverlay";
import { AppDispatch } from "../../stateManagement/services/rootDispatcher";
const ResetValidator: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const routeLocation = useLocation();
  const searchParams = new URLSearchParams(routeLocation.search);

  const token = searchParams.get("token");
  // const token: any = localStorage.getItem(StorageKeys.RESET_VALIDATOR_TO_LOGIN);
  const shouldComponentShow = useSelector(
    (state: any) => state?.securityReducer?.validationSuccess
  );

  localStorage.removeItem("resetToLogin");
  useEffect(() => {
    const body = {
      ResetPasswordToken: `${token}`,
    };
    dispatch(resetValidatorAction(body));
  }, [dispatch, token]);


  return (
    <>
      {shouldComponentShow ? (
        <ResetPassword token={token} />
      ) : (
        <div
          style={{
            display: "flex",
            height: "100vh",
            justifyContent: "center",
          }}
        >
          <LoadingOverlay isVisible={true} />
          <Typography sx={{ marginTop: "40vh" }}>
            {TitleConstants.LOADING_INDICATOR}
          </Typography>
        </div>
      )}
    </>
  );
};

export default ResetValidator;
