import { shallowEqual } from "react-redux";
import UserDetails from "../../../components/SettingsComponent/UserDetails";
import { settingPageStyles as styles } from "./styles";
import { FC, useEffect } from "react";
import { getUserDetail } from "../../../stateManagement/actions/securityActions";
import LoadingOverlay from "../../../components/Loader/LoadingOverlay";
import { useAppSelector } from "../../../hooks/hooks";
import { RootState } from "../../../stateManagement/services/rootDispatcher";

const Settings: FC = () => {
  const [UserData, loginLoader] = useAppSelector((state: RootState) => {
    return [
      state.securityReducer?.userDetailsSuccessMsg?.PayLoad?.UserDetails,
      state.securityReducer?.loginLoader,
    ];
  }, shallowEqual);

  useEffect(() => {
    const handlePageLoad = () => {
      getUserDetail();
    };
    if (document.readyState === "complete") {
      // If the page is already loaded, call the handler immediately
      handlePageLoad();
    } else {
      // Otherwise, wait for the window load event
      window.addEventListener("load", handlePageLoad);
      // Cleanup the event listener on component unmount
      return () => {
        window.removeEventListener("load", handlePageLoad);
      };
    }
  }, []); //

  return (
    <div style={styles.mainSection}>
      <LoadingOverlay isVisible={loginLoader} />
      <UserDetails UserData={UserData} />
    </div>
  );
};
export default Settings;
