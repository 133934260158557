import { Box, colors } from "@mui/material";
import { FieldError, Merge, FieldErrorsImpl } from "react-hook-form";
import { showErrorMessage } from "../../../utils/CommonUtils";
import { updateRulesListColor } from "../../../stateManagement/actions/securityActions";
import { NumericConstants } from "../../../constants/CommonConstants";

const CheckAndSetError = (
  passwordValue:
    | FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined
): JSX.Element => {
  updateRulesListColor();
  if (passwordValue) {
    let message = passwordValue.message?.toString();
    let errorCaption = "";
    switch (message) {
      case "NEW_PASSWORD_REQUIRED":
        errorCaption = "New Password is required";
        break;
      case "CONFIRM_NEW_PASSWORD_REQUIRED":
        errorCaption = "Confirm Password is required";
        break;
      case "MIN_PASSWORD_REQUIREMENT":
        errorCaption = `Password must be at least ${NumericConstants.PASSWORD_MIN_LENGTH} characters long`;
        break;
      case "MAX_PASSWORD_REQUIREMENT":
        errorCaption = `Password must less that ${NumericConstants.PASSWORD_MAX_LENGTH} characters`;
        break;
      case "UPPER_LOWER_CASE_VALIDATION":
        errorCaption =
          "Password must contain at least an upper case and a lower case character";
        break;
      case "NUMERIC_VALIDATION":
        errorCaption = "Password must contain at least a number";
        break;
      case "SPECIAL_CHAR_VALIDATION":
        errorCaption =
          "Password must contain at least one designated special character";
        break;
      case "PASSWORDS_MISMATCH_ERROR":
        errorCaption = "Passwords do not match";
        break;
      case "SPACE_DETECTED":
        errorCaption = "Space not allowed in password";
        break;
      default:
        errorCaption = "Some error with new password";
        break;
    }
    return (
      <Box marginLeft={"14px"} color={colors?.red}>
        {showErrorMessage(Boolean(passwordValue), errorCaption)}
      </Box>
    );
  }
  return <></>;
};

export default CheckAndSetError;
