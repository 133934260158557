export const colors = {
  themeBg: "#24448a",
  themeBlue: "#24448a",
  textColor: "#212121",
  fullBlack: "#000000",
  fullWhite: "#FFFFFF",
  purple: "#5245AF",
  purpleLight: "#5c50b3",
  grey: "#C8C8C8",
  blackLight: "#292929",
  green: "#3A934E",
  red: "#d32f2f",
  skyBlue: "#4F8BF3",
  defaultRed: "#FF0000",
  defaultGrey: "#808080",
};
