import { API } from "../../apiCollection/APICollection";

export const LoginRoute = {
  url: API.login,
  method: "POST",
};
export const forgotPasswordRoute = {
  url: API.forgetPassword,
  method: "POST",
};
export const resetPasswordRoute = {
  url: API.resetPassword,
  method: "POST",
};
export const resetPasswordValidatorRoute = {
  url: API.resetPasswordValidator,
  method: "POST",
};

export const TenantDetailsRoute = {
  url: API.tenantDetails,
  method: "GET",
};

export const UserDetailRoute = (params: string) => {
  return {
    url: API.userDetails + params,
    method: "GET",
  };
};

export const DosageFormsRoute = {
  url: API.dosageFormList,
  method: "POST",
};

export const SaveProductevaluationRoute = {
  url: API.saveproductevaluation,
  method: "POST",
};

export const ProductEvaluationListRoute = {
  url: API.productEvaluationList,
  method: "POST",
};

export const ProductEvaluationOutputRoute = (params: string) => {
  return {
    url: API.productEvaluationOutput + params,
    method: "GET",
  };
};

export const ProductEvaluationEditDetailRoute = (params: string) => {
  return {
    url: API.getproductevaluation + params,
    method: "GET",
  };
};

export const ProductEvaluationEditResultRoute = (params: string) => {
  return {
    url: API.getProductEvaluationResults + params,
    method: "GET",
  };
};
