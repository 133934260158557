import { Typography } from '@mui/material';
import React from 'react'
import { colors } from '../../config/theme';

interface IProps {
    label: string;
}
const TextFieldLabel: React.FC<IProps> = ({
    label
}) => {
    return (
        <Typography
            color={colors?.fullBlack}
            sx={{
                marginBottom: "8px", // Space between label and text field
                fontSize: {
                    xs: "10px",
                    sm: "11px",
                    md: "14px",
                    lg: "17px",
                    xl: "20px",
                },
            }}
            fontFamily="Roboto"
            fontWeight={"500"}
        >
            {label}
        </Typography>
    )
}

export default TextFieldLabel