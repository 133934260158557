import { colors } from "../../../config/theme";

export const resetPasswordPageStyles = {
  mainGridContainer: {
    overflow:'auto',
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  paperContainer: { padding: "20px" },
  formContainerStyle: {
    display: "flex",
    border: "1px solid #707070",
    flexDirection: "column",
    gap: "16px",
    margin: "auto",
    padding: "65px",
  } as React.CSSProperties,
  pageTitlelabel: {
    color: colors?.purple,
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: {
      xs: "16px",
      sm: "17px",
      md: "19px",
      lg: "21px",
      xl: "24px",
    },
    lineHeight: {
      xs: "21px",
      sm: "21px",
      md: "23px",
      lg: "26px",
      xl: "29px",
    },
    // You can add additional styles here if needed
  },
  passInputStyle: {
    fontSize: {
      xs: "10px",
      sm: "11px",
      md: "14px",
      lg: "17px",
      xl: "20px",
    },
    fontWeight: "400",
    fontFamily: "Inter",
    lineHeight: {
      xs: "10px",
      sm: "15px",
      md: "18px",
      lg: "21px",
      xl: "24.2px",
    },
  },
  buttonContainer: {
    display: "grid",
    justifyContent: "space-evenly",
    gridTemplateColumns: "auto auto",
  },
  buttonStyle: {
    margin: "auto",
    color: colors?.fullWhite,
    textTransform: "capitalize",
    backgroundColor: colors?.purple,
    borderRadius: "10px",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: {
      xs: "14px",
      sm: "15px",
      md: "17px",
      lg: "19px",
      xl: "20px",
    },
    lineHeight: {
      xs: "21px",
      sm: "21px",
      md: "24px",
      lg: "26px",
      xl: "28px",
    },
    padding: "10px 25px",
    border: "1px solid #000000",
    "&:hover": {
      backgroundColor: colors?.purpleLight,
    },
    "&.Mui-disabled": {
      background: colors?.grey,
      color: colors?.blackLight,
    },
  },
  listContainer: {
    fontFamily: "Inter",
    fontSize: {
      xs: "10px",
      sm: "12px",
      md: "14px",
      lg: "16px",
      xl: "16px",
    },
    fontWeight: "400",
    lineHeight: {
      xs: "14px",
      sm: "16px",
      md: "18px",
      lg: "20px",
      xl: "22px",
    },
    listStyleType: "disc",
    listStylePosition: "inside",
    pl: 0,
    "& .MuiListItem-root": {
      display: "list-item",
    },
  },
  nestedListContainer: {
    listStyleType: "none",
    pl: 2,
    "& .MuiListItem-root": {
      display: "list-item",
    },
  },
};
