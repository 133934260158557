import { Link, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import {
  ImagesAndLogos,
  TitleConstants,
  ValidationConstants,
} from "../../../constants/CommonConstants";
import { forgotPasswordAction } from "../../../stateManagement/actions/securityActions";
import { showErrorMessage, validateEmail } from "../../../utils/CommonUtils";
import { forgetPasswordStyles as styles } from "./styles";
import LoadingOverlay from "../../../components/Loader/LoadingOverlay";
import { AppDispatch, RootState } from "../../../stateManagement/services/rootDispatcher";

const ForgotPassword = (): JSX.Element => {
  const navigateToRoute = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [loginLoader, message]: any = useSelector((state: RootState) => {
    return [state?.securityReducer?.loginLoader, state];
  }, shallowEqual);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm();

  const submitResetPasswordForm = async (data: any) => {
    const userCredentials = {
      username: data.email,
    };
    await dispatch(forgotPasswordAction(userCredentials, navigateToRoute));
  };

  return (
    <Grid container style={styles.mainGridContainer}>
      <LoadingOverlay isVisible={loginLoader} />
      <Grid item xs={12} sm={6}>
        <Paper elevation={3} style={styles.paperContainer}>
          <form
            style={styles.formContainerStyle}
            onSubmit={handleSubmit((data) => submitResetPasswordForm(data))}
          >
            <Typography sx={styles.pageTitlelabel}>
              {TitleConstants.FORGOT_YOUR_PASSWORD}
            </Typography>
            <Box sx={styles.inputContainerStyle}>
              <Typography sx={styles.inputTitle}>
                {TitleConstants.EMAIL}
              </Typography>
              <TextField
                error={Boolean(errors.email)}
                helperText={showErrorMessage(
                  Boolean(errors.email),
                  ValidationConstants.EMAIL_VALIDATION
                )}
                type={"text"}
                sx={styles.emaiInputStyle}
                placeholder={""}
                {...register("email", {
                  validate: (email) => validateEmail(email),
                })}
              />
            </Box>
            <Typography sx={styles.infoLabelStyle}>
              {TitleConstants.ONCE_SUBMIT_EMAIL_ADDRESS}
            </Typography>
            <Box sx={styles.buttonContainerStyle}>
              <Button
                disabled={!isValid || !isDirty || loginLoader}
                sx={styles.buttonStyle}
                type="submit"
              >
                {TitleConstants.CONTINUE}
              </Button>
              <Link to="/">
                <Button sx={styles.buttonStyle}>{TitleConstants.CANCEL}</Button>
              </Link>
            </Box>
            {message?.securityReducer?.errorMessage && (
              <Typography sx={styles.errorMessageLabel}>
                {TitleConstants.SOMETHING_WENT_WRONG}
              </Typography>
            )}
          </form>
        </Paper>
      </Grid>
      <img
        src={ImagesAndLogos?.PORDUCT_LOGO}
        className="logoBottomRight lazyloaded"
        data-ll-status="loaded"
        alt="logo"
      />
    </Grid>
  );
};

export default ForgotPassword;
