// tokenExpiryMiddleware.ts
import { Middleware } from "redux";
import { RootState } from "../services/rootDispatcher";
import { resetAll } from "../actions/resetActions";

const tokenExpiryMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const state = store.getState();
    const tokenExpiryTime = state?.securityReducer?.user?.Payload?.TokenEndTime;
    if (tokenExpiryTime && new Date().getTime() >= tokenExpiryTime) {
      resetAll();
    }
    return next(action);
  };

export default tokenExpiryMiddleware;
