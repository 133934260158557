import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";
import { TitleConstants } from "../../constants/CommonConstants";
import {
  CommonTableStyles as styles,
} from "./styles";

type MenuLIstProps = {
  columnData:any[];
  rowsData:any[];
};

const MenuLIst:FC<MenuLIstProps> = (props) => {
  const {columnData,rowsData,} = props;
  const [exportOption, setExportOption] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setExportOption(event.currentTarget);
  };

  const handleClose = () => {
    setExportOption(null);
  };

  const exportToExcel = () => {
    // Create an array of header objects for Excel
    const headers = columnData.map((col: any) => col?.label);
    const data = rowsData.map((row: any) =>
      columnData.map((col) => row[col?.id])
    );

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
    XLSX.writeFile(workbook, "GaltData.xlsx");
  };

  const handleMenuItemClick = (option: string) => {
    if (option === TitleConstants.EXPORT_TO_EXCEL) {
      exportToExcel();
    }
    handleClose();
  };

  return (
    <>
      <Box>
        <Button
          aria-controls="export-menu"
          aria-haspopup="true"
          onClick={handleClick}
          startIcon={<DownloadIcon />}
          sx={styles.exportButtonStyles}
        >
          {TitleConstants.EXPORT}
        </Button>
        <Menu
          id="export-menu"
          anchorEl={exportOption}
          keepMounted
          open={Boolean(exportOption)}
          onClose={handleClose}
        >
          <MenuItem
            disabled={rowsData?.length > 0 ? false : true}
            onClick={() => handleMenuItemClick(TitleConstants.EXPORT_TO_EXCEL)}
          >
            {TitleConstants.EXPORT_TO_EXCEL}
          </MenuItem>
          <MenuItem disabled={rowsData?.length > 0 ? false : true}>
            <CSVLink
              data={rowsData}
              headers={columnData.map((col: any) => ({
                label: col?.label,
                key: col?.id,
              }))}
              filename={"Galt.csv"}
              style={styles.CSVLinkStyle}
            >
              {TitleConstants.EXPORT_TO_CSV}
            </CSVLink>
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};

export default MenuLIst;
