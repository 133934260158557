import { SET_ERROR_LOGS } from "../types";

const initialState = {
  isNofityShow: false,
  errorData: null,
};
const errorReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ERROR_LOGS:
      return {
        ...state,
        isNofityShow: action.payload?.isNofityShow,
        errorData: action.payload,
      };
    default:
      return state;
  }
};

export default errorReducer;
