// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoDrawer {
    height: 50px; /* Adjust height as needed */
    width: auto;
  }

  .drawerTopBar {
    background-color: white;
    height: 80px;
    width: auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }`, "",{"version":3,"sources":["webpack://./src/components/NavigationDrawer/styles.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,4BAA4B;IAC1C,WAAW;EACb;;EAEA;IACE,uBAAuB;IACvB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf","sourcesContent":[".logoDrawer {\n    height: 50px; /* Adjust height as needed */\n    width: auto;\n  }\n\n  .drawerTopBar {\n    background-color: white;\n    height: 80px;\n    width: auto;\n    align-items: center;\n    justify-content: center;\n    display: flex;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
