import {
  RESET_ROUTE_DATA,
  SAVE_CURRENT_PAGE_DATA,
  SAVE_CURRENT_PAGE_NAME,
} from "../types";

const initialState = {
  prevousPageName: null,
  savedData: null,
};
const historyReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SAVE_CURRENT_PAGE_DATA:
      return {
        ...state,
        savedData: action.payload,
      };
    case SAVE_CURRENT_PAGE_NAME:
      return {
        ...state,
        prevousPageName: action.payload,
      };
    case RESET_ROUTE_DATA:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default historyReducer;
