import {
  DosageFormsRoute,
  SaveProductevaluationRoute,
} from "./apiServices/routeObjects";
import { ApiClient } from "./apiServices/apiClient";

export const dosageFormListService = async (payload: any) => {
  try {
    let obj = {
      ...DosageFormsRoute,
      data: { ...payload },
    };
    const response = await ApiClient(obj);
    return response.data;
  } catch (error: any) {
    throw error.data || error;
  }
};

export const saveProductEvaluationService = async (payload: any) => {
  try {
    let obj = {
      ...SaveProductevaluationRoute,
      data: {
        ExecutionDefinitionId: payload?.ExecutionDefinitionId || "",
        ExecutionDefinitionName: payload?.ExecutionDefinitionName || "",
        SelectedDosageForms: payload?.SelectedDosageForms || [],
        FDAOffPatentFreshDataDownloadBl:
          payload?.FDAOffPatentFreshDataDownloadBl || false,
        OrangeBookMaxRXCount: Number(payload?.OrangeBookMaxRXCount) || 0,
        OrangeBookFreshDataDownloadBl:
          payload?.OrangeBookFreshDataDownloadBl || false,
        AnalysourceMaxActiveCount:
          Number(payload?.AnalysourceMaxActiveCount) || 0,
        TrademarkIgnoreBrandList: payload?.TrademarkIgnoreBrandList || [],
      },
    };
    const response = await ApiClient(obj);
    return response.data;
  } catch (error: any) {
    throw error.data || error;
  }
};
